export const SORT_OPTIONS = Object.freeze({
  defaultSortOption: 'nameAZ',
  options: [
    {
      text: 'Name A-Z',
      queryStringValue: 'nameAZ',
      key: 'name',
      value: 'asc',
    },
    {
      text: 'Name Z-A',
      queryStringValue: 'nameZA',
      key: 'name',
      value: 'desc',
    },
  ],
})

export const ORDER_TYPE = Object.freeze({
  defaultOrderTypeOption: 'direct_to_lab',
  options: [
    {
      text: 'Submit directly to Lab',
      label: 'Lab Contract Price',
      queryStringValue: 'direct_to_lab',
      key: 'order_type',
      value: '1',
    },
    {
      text: 'Send to Patient for Purchase',
      label: 'Patient Retail Price',
      queryStringValue: 'patient_to_purchase',
      key: 'order_type',
      value: '2',
    },
    {
      text: 'Bill to Client',
      label: 'Bill to Client',
      queryStringValue: 'bill_to_client',
      key: 'order_type',
      value: '3',
    },
  ],
})
export const LAB_TYPE = Object.freeze({
  defaultLabTypeOption: 'existing_package',
  options: [
    {
      text: 'Existing Package',
      queryStringValue: 'existing_package',
      key: 'lab_type',
      value: '1',
    },
    {
      text: 'Custom Package',
      queryStringValue: 'custom_package',
      key: 'lab_type',
      value: '2',
    },
    {
      text: 'Custom Order',
      queryStringValue: 'custom_order',
      key: 'lab_type',
      value: '3',
    },
  ],
})
export const PLAN_TYPE = Object.freeze({
  defaultLabTypeOption: 'ppo',
  options: [
    {
      text: 'PPO',
      queryStringValue: 'ppo',
      key: 'plan_type',
      value: 'ppo',
    },
    {
      text: 'EPO',
      queryStringValue: 'epo',
      key: 'plan_type',
      value: 'epo',
    },
    {
      text: 'HMO',
      queryStringValue: 'hmo',
      key: 'plan_type',
      value: 'hmo',
    },
  ],
})
export const INSURANCE_PROVIDERS = Object.freeze({
  defaultLabTypeOption: 'ppo',
  options: [
    {
      text: '21st Century Casualty',
      queryStringValue: '21st-century-casualty',
      key: 'insurance_provider',
      value: '36404',
      // image: { avatar: true, src: 'https://i.pravatar.cc/30' },
    },
    {
      text: '21st Century Insurance',
      queryStringValue: '21st-century-insurance',
      key: 'insurance_provider',
      value: '12963',
    },
    {
      text: '5 Star Life Insurance',
      queryStringValue: '5-star-life-insurance',
      key: 'insurance_provider',
      value: '77879',
    },
    {
      text: 'AAA Life Insurance',
      queryStringValue: 'aaa-life-insurance',
      key: 'insurance_provider',
      value: '71854',
    },
    {
      text: 'ACA Financial Guaranty Corporation',
      queryStringValue: 'aca-financial-guaranty-corporation',
      key: 'insurance_provider',
      value: '22896',
    },
    {
      text: 'ACA Insurance',
      queryStringValue: 'aca-insurance',
      key: 'insurance_provider',
      value: '10921',
    },
    {
      text: 'Acacia Life Insurance',
      queryStringValue: 'acacia-life-insurance',
      key: 'insurance_provider',
      value: '60038',
    },
    {
      text: 'Accendo Insurance',
      queryStringValue: 'accendo-insurance',
      key: 'insurance_provider',
      value: '63444',
    },
    {
      text: 'Acceptance Indemnity Insurance',
      queryStringValue: 'acceptance-indemnity-insurance',
      key: 'insurance_provider',
      value: '20010',
    },
    {
      text: 'Access Insurance',
      queryStringValue: 'access-insurance',
      key: 'insurance_provider',
      value: '11711',
    },
  ],
})

export const DEFAULT_PROJECT = '5e53da61f5935b001ab1a0f4'
