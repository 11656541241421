/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useQuery } from '@apollo/react-hooks'
import { useHistory, useLocation, Prompt } from 'react-router-dom'

// Components
import { Grid, Popup, Icon } from 'semantic-ui-react'
import { Button, Form, InputField } from '@labsavvyapp/ui-components'
import TestSelectionModal from '../../../components/Modals/TestSelectionModal/TestSelectionModal'
import TemplateSearch from './TemplateSearch/TemplateSearch'
import NewLabOrder from './NewLabOrder'
import CreateLabOrderModal from '../../../components/Modals/CreateLabOrderModal/CreateLabOrderModal'

// Utilities
import { camelizeKeys } from 'humps'
import { fetchNext, hasMore } from '@labsavvyapp/ui-components/lib/utils'
import useLocalReducer from '../../../hooks/useLocalReducer'
import formatterPrice from '../../../utils/formatter-price'

// Styling
import style from './NewLabOrder.module.css'

// Constants
import { LAB_REPORTS, ORDERS_SENT } from '../../../config/routes'

// Queries and Mutations
import { GetMe } from '../../../graphql/user/queries.js'
import { PartnerCompendium } from '../../../graphql/lab-orders/queries'
import { ListPartnerMiscPricing } from '../../../graphql/partners/queries.js'
import { ListProviders } from '../../../graphql/providers/queries.js'
import { GetPatient } from '../../../graphql/clients/queries.js'
import { ListConfigs } from '../../../graphql/configs/queries'

let fetchNextCompendiumPanels

const TABS = Object.freeze({
  EXISTING: '1',
  CUSTOM: '2',
})

const initialState = {
  packageSearch: '',
  selectedPackage: null,
  selectedProvider: null,
  selectedPanels: [],
  selectedCompendiumPanels: [],
  selectedTests: [],
  orderName: '',
  isModalOpen: false,
  hasMoreCompendiumPanels: undefined,
  searchString: '',
  miscPricing: [],
}

export default function NewLabOrderContainer({ me }) {
  const [showClientModal, setShowClientModal] = useState(false)
  const [isVanguard, setIsVanguard] = useState(false)

  const { clientId, provider, orderType, labType } = JSON.parse(
    localStorage.getItem('createLabOrder'),
  )

  const [packageSearch, setPackageSearch] = useState('')
  const { push } = useHistory()
  const { pathname } = useLocation()

  /** START OF Speciment Types Code */
  const [specimenTypes, setSpecimenTypesList] = useState([])

  // Query: Fetch specimen type master data
  const { data } = useQuery(ListConfigs, {
    variables: {
      limit: 500,
      sort: {},
      filter: {
        domain: 'MASTERDATA',
        subcategory: 'SPECIMENTYPE',
      },
      page: 1,
    },
  })

  if (data?.listConfigs?.total > 0 && specimenTypes.length == 0) {
    setSpecimenTypesList(
      data?.listConfigs?.configs.map((sampletype) => ({
        value: sampletype.code,
        text: sampletype.name,
      })),
    )
  }

  // handle the selection of Specimen types
  const handleSpecimentType = (code) => {
    const specimen_type = code.split(';')
    // TODO: Only add the specimen to the matching code
    const selectedCompendiumPanelsAdjusted = selectedCompendiumPanels.map(
      (compendia) => {
        if (compendia.code == specimen_type[1]) {
          return { ...compendia, specimen: { type: specimen_type[0] } }
        }
        return compendia
      },
    )
    setState({ selectedCompendiumPanels: selectedCompendiumPanelsAdjusted })
  }
  /** END OF Speciment Types Code */

  // Query: Fetch client data
  const { data: clientResponse } = useQuery(GetPatient, {
    variables: { id: clientId },
  })

  // Query: Current Active Partner
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const partnerId = parsedUserData?.getMe?.partner?.id

  const [state, setState] = useLocalReducer(
    'new_lab_order',
    (state, action) => ({ ...state, ...action }),
    { ...initialState, selectedClient: clientId || null },
  )

  useEffect(() => {
    const is_vanguard =
      me?.partner?.config?.ordering?.default_laboratory?.code === 'VNLAB'
    setIsVanguard(is_vanguard)
    setState({ isModalOpen: is_vanguard })
  }, [me])

  useEffect(() => {
    if (localStorage.getItem('labOrder')) {
      const {
        selectedPackage,
        packageSearch,
        selectedPanels,
        selectedCompendiumPanels,
        orderName,
      } = JSON.parse(localStorage.getItem('labOrder'))
      setSelectedPackage(selectedPackage)
      setPackageSearch(packageSearch)
      setState({
        packageSearch: packageSearch,
        selectedPackage: selectedPackage,
        selectedPanels: selectedPanels,
        selectedCompendiumPanels: selectedCompendiumPanels,
        orderName: orderName,
      })
    }
  }, [])

  const {
    selectedPanels,
    selectedCompendiumPanels,
    selectedTests,
    selectedClient,
    orderName,
    isModalOpen,
    hasMoreCompendiumPanels,
    searchString,
  } = state

  const { data: providersData, loading: providersLoading } =
    useQuery(ListProviders)

  const laboratoryOptions =
    (providersData &&
      providersData.listProviders.providers.map(({ value, name }) => ({
        key: value,
        value: value,
        text: name,
      }))) ||
    []

  const {
    data: compendiumPanelsData,
    refetch: refetchPanels,
    fetchMore: fetchMorePanels,
  } = useQuery(PartnerCompendium, {
    skip: !laboratoryOptions || laboratoryOptions.length === 0 || !partnerId,
    variables: {
      limit: 20,
      sort: {},
      filter: {
        search: searchString,
        partnerId: partnerId,
        providerId: provider.value,
      },
    },
  })

  const compendiumPanels = camelizeKeys(compendiumPanelsData)
  const compendiumPanelsList = compendiumPanels?.partnerCompendium.panels
    .map((item) => {
      const {
        lsCost = 0,
        lsStandardPrice = 0,
        standardRetailPrice = 0,
        contractPrice = 0,
        retailPrice = 0,
      } = item.partnerPricing
      return {
        ...item,
        selected:
          !lsCost ||
          !retailPrice ||
          !contractPrice ||
          !lsStandardPrice ||
          !standardRetailPrice
            ? true
            : false,
      }
    })
    .sort((a, b) => {
      if (a.selected > b.selected) return 1
      if (a.selected < b.selected) return -1
      return 0
    })

  // Goups > Pagination
  const panelsPage = compendiumPanels && compendiumPanels.partnerCompendium.page
  const panelsPages =
    compendiumPanels && compendiumPanels.partnerCompendium.pages

  useEffect(() => {
    setState({
      hasMoreCompendiumPanels: hasMore({
        page: panelsPage,
        pages: panelsPages,
      }),
    })
    fetchNextCompendiumPanels = () => {
      fetchNext('partnerCompendium', 'panels', {
        page: panelsPage,
        fetchMore: fetchMorePanels,
        filter: {
          search: searchString,
          partnerId: partnerId,
          providerId: provider.value,
        },
      })
    }
  }, [panelsPage, panelsPages])

  useEffect(() => {
    if (partnerId && provider.value) {
      refetchPanels()
    }
  }, [searchString])

  // Fetch miscellaneous pricing
  const { data: partnerMiscPricingData } = useQuery(ListPartnerMiscPricing, {
    skip: !laboratoryOptions || laboratoryOptions.length === 0 || !partnerId,
    variables: {
      partnerId: partnerId,
      providerId: provider.value,
    },
  })

  const partnerMiscPricing =
    (partnerMiscPricingData &&
      partnerMiscPricingData.listPartnerMiscPricing.misc_pricing.map(
        ({ name, code, contract_price, retail_price }) => ({
          name: name,
          code: code,
          contract_price: contract_price,
          retail_price: retail_price,
        }),
      )) ||
    []

  // Helpers
  const isDirty = () => {
    const { selectedPackage, selectedClient, selectedCompendiumPanels } = state
    if (labType.value === TABS.CUSTOM) {
      return Boolean(
        (selectedPackage || selectedCompendiumPanels.length) &&
          selectedClient &&
          Boolean(orderName) &&
          !/^\s*$/.test(orderName),
      )
    } else if (labType.value === TABS.EXISTING)
      return Boolean(
        (selectedPackage || selectedCompendiumPanels.length) &&
          selectedClient &&
          packageSearch,
      )
  }

  // Filter handlers
  // const onClientSelect = (client) => {
  //   setState({ selectedClient: client ? client.id : selectedClient })
  // }
  const handleCompendiumSearch = (value) => setState({ searchString: value })

  const [selectedPackage, setSelectedPackage] = useState()

  const onTemplateSelect = ({
    package: { name, categories, id, lsPrice, partnerPrice, retailPrice },
  }) => {
    const panels = categories.flatMap(({ panels }) => panels)

    const packagePanels =
      panels &&
      panels.map((panel) => ({
        code: panel.code,
        name: panel.name,
        price: lsPrice,
        tests: panel.tests.map((test) => ({
          id,
          order: test.order,
          result: test.result,
          misc: test.misc,
        })),
      }))
    packagePanels && setState({ selectedPanels: packagePanels })

    setSelectedPackage({
      name: name,
      categories: categories,
      id: id,
      lsPrice: lsPrice,
      partnerPrice: partnerPrice,
      retailPrice: retailPrice,
    })

    setState({ selectedPackage: id })
  }

  const reviewOrder = async (values) => {
    const data = {
      patient_id: clientId,
      misc_pricing: partnerMiscPricing,
    }

    const custom_package_pricing = selectedCompendiumPanels.map(
      ({ partnerPricing }) => ({ partnerPricing }),
    )

    if (labType.value === TABS.CUSTOM) {
      data.name = orderName
      data.order_codes = selectedCompendiumPanels.map(({ code }) => code)
      // data.misc.order_codes = selectedCompendiumMiscPanels.map(({ code }) => code)
    } else {
      data.package_id = selectedPackage.id
    }

    data.order_items_data = [
      ...selectedPanels.map(({ code }) => generateOrderItem(code, values)),
      ...selectedCompendiumPanels.map(({ code, specimen }) =>
        generateOrderItem(code, values, specimen),
      ),
    ]
    data.is_package_reusable = false //default
    data.order_type = orderType
    data.custom_package_pricing = custom_package_pricing
    data.provider_id = provider.value
    setState({ reviewSubmitData: data })

    const metadata = {}
    metadata.order_type = orderType
    metadata.lab_type = labType
    metadata.total_price = calculateSubtotalPrice()
    metadata.provider = laboratoryOptions?.[0]

    setState({ metadata: metadata })

    //Save data as localStorage: To be used when editing order from review
    localStorage.setItem(
      'labOrder',
      JSON.stringify({
        clientId,
        orderType,
        providerId: provider.value,
        data,
        metadata,
        orderName: orderName || packageSearch,
        packageSearch,
        selectedPackage: selectedPackage,
        selectedPanels,
        selectedCompendiumPanels,
      }),
    )

    setTimeout(function () {
      if (pathname.includes(LAB_REPORTS.base)) {
        push(LAB_REPORTS.review)
      } else {
        push(ORDERS_SENT.review)
      }
    }, 0)
  }

  const onResultsSubmit = (code) => {
    const panels = compendiumPanels?.partnerCompendium?.panels.filter(
      (item) => item.code === code,
    )
    //check if code is already found in selectedCompendiumPanels
    const isFound = !!selectedCompendiumPanels.find(
      (array1) => array1.code === code,
    )
    setState({
      selectedCompendiumPanels: isFound
        ? [...selectedCompendiumPanels]
        : [...selectedCompendiumPanels, ...panels],
      isModalOpen: false,
    })
  }
  const onResultsSubmitList = (codeList) => {
    let newPanels = []
    codeList.forEach((code) => {
      //check if code is already found in selectedCompendiumPanels
      const isFound = !!selectedCompendiumPanels.find(
        (panel) => panel.code === code.code,
      )
      if (!isFound) {
        newPanels = newPanels.concat([code])
      }
    })

    if (newPanels.length > 0) {
      setState({
        selectedCompendiumPanels: [...selectedCompendiumPanels, ...newPanels],
      })
    }

    setState({
      isModalOpen: false,
    })
  }

  const generateOrderItem = (code, values, specimen) => ({
    order_code: code,
    specimen: {
      type: specimen?.type ?? '',
    },
  })
  // const handleLaboratoryChange = (value) => {
  //   setState({ selectedProvider: value })
  // }
  const handleOrderNameChange = (value) => {
    setState({ orderName: value })
  }

  const calculateSubtotalPrice = () => {
    // Calculate Miscellaneous Pricing Total
    const queryMisc = orderType.queryStringValue.includes('direct_to_lab')
      ? 'contract_price'
      : 'retail_price'

    const miscSubtotal = partnerMiscPricing.reduce(
      (a, b) => a + (b[queryMisc] || 0),
      0,
    )

    let total
    if (labType.queryStringValue.includes('existing_package')) {
      //Calculate Existing Package Total
      total = orderType.queryStringValue.includes('direct_to_lab')
        ? selectedPackage?.partnerPrice
        : selectedPackage?.retailPrice
    } else {
      //Calculate Custom Package Total
      const queryPackage = orderType.queryStringValue.includes('direct_to_lab')
        ? 'contractPrice'
        : 'retailPrice'

      total = selectedCompendiumPanels.reduce(
        (a, b) => a + (b.partnerPricing[queryPackage] || 0),
        0,
      )
    }

    return formatterPrice(miscSubtotal + total)
  }

  const clearChanges = () => {
    setSelectedPackage(null)
    setPackageSearch('')
    setState({
      selectedProvider: initialState.selectedProvider,
      selectedPanels: initialState.selectedPanels,
      selectedCompendiumPanels: initialState.selectedCompendiumPanels,
      selectedTests: initialState.selectedTests,
      orderName: initialState.orderName,
      isModalOpen: initialState.isModalOpen,
      hasMoreCompendiumPanels: initialState.hasMoreCompendiumPanels,
      searchString: initialState.searchString,
      miscPricing: initialState.miscPricing,
    })
  }

  return (
    <div className={style.pageContainer}>
      <Helmet>
        <title>New Lab Order</title>
      </Helmet>

      <div className={style.formContainer}>
        <Form
          className={style.form}
          initialFormData={{ order_name: orderName }}
        >
          <Grid padded className={style.partnerAndClientFilters}>
            <Grid.Row className={style.pageHeaderContainer}>
              <Grid.Column tablet={16} computer={5} largeScreen={5}>
                <div className={style.pageTitle}>
                  <h2>New Lab Order</h2>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column computer={3}>
                <div style={{ marginLeft: 20, marginTop: 20 }}>
                  {!isVanguard && (
                    <div>
                      {labType && labType.text === 'Existing Package'
                        ? 'Select an '
                        : 'Create a '}
                      {labType && labType.text + ' '}
                      for
                    </div>
                  )}
                  <b>{`${clientResponse?.getPatient?.data?.name?.display} `}</b>
                  <span
                    style={{ paddingLeft: 5, cursor: 'pointer' }}
                    onClick={() => setShowClientModal(true)}
                  >
                    <Icon name="edit" color="purple" />
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column computer={13} tablet={10}>
                <div className={style.filterColumn}>
                  {labType.value === TABS.CUSTOM ? (
                    <div className={style.customOrderInputContainer}>
                      <div className={style.customOrderInput}>
                        {!isVanguard && (
                          <InputField
                            label="Order name (REQUIRED)"
                            name="order_name"
                            placeholder="e.g. Custom order"
                            onChange={handleOrderNameChange}
                            value={orderName}
                            className={style.patientSearchField}
                          />
                        )}
                      </div>

                      <Popup
                        trigger={
                          <div
                            className={style.plusIcon}
                            onClick={() => setState({ isModalOpen: true })}
                          >
                            +
                          </div>
                        }
                        content="Add a new test"
                        position="left center"
                      />
                    </div>
                  ) : (
                    <TemplateSearch
                      label="Choose a package"
                      search={packageSearch}
                      onChange={(packageSearch) => {
                        setPackageSearch(packageSearch)
                        isDirty()
                      }}
                      onSelect={onTemplateSelect}
                      providersLoading={providersLoading}
                      providerId={provider.value}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className={style.packagesContainer}>
            {labType.value === TABS.CUSTOM ? (
              // Custom Package
              <NewLabOrder
                packageSearch={packageSearch}
                selectedPanels={selectedPanels}
                selectedCompendiumPanels={selectedCompendiumPanels}
                selectedTests={selectedTests}
                labOrderType={orderType}
                labType={labType}
                miscPrices={partnerMiscPricing}
                setState={setState}
                isVanguard={isVanguard}
                specimenTypes={specimenTypes}
                handleSpecimentTypeFunc={handleSpecimentType}
              />
            ) : (
              // Existing Package
              <>
                <NewLabOrder
                  packageSearch={packageSearch}
                  selectedPanels={selectedPanels}
                  selectedCompendiumPanels={selectedCompendiumPanels}
                  selectedTests={selectedTests}
                  labOrderType={orderType}
                  labType={labType}
                  miscPrices={partnerMiscPricing}
                  selectedPackage={selectedPackage}
                />
              </>
            )}
          </div>
          {!isVanguard && (
            <div className={style.subtotalContainer}>
              <div>
                Laboratory: <b>{provider.text}</b> | Lab Type:{' '}
                <b>{labType.text}</b> | Order Type: <b>{orderType.text}</b>
              </div>

              <div className={style.pullRight}>
                Subtotal: <b>{calculateSubtotalPrice()}</b>
              </div>
            </div>
          )}
        </Form>
      </div>

      <div className={style.buttonsContainer}>
        <Button
          data-test="button-submit-order"
          disabled={isVanguard ? !selectedCompendiumPanels.length : !isDirty()}
          onClick={reviewOrder}
        >
          Review Order
        </Button>

        <Button
          data-test="cancel-button"
          variant="basic"
          onClick={() => push(LAB_REPORTS.base)}
        >
          Cancel
        </Button>
      </div>

      <TestSelectionModal
        open={isModalOpen}
        items={compendiumPanelsList}
        onNext={fetchNextCompendiumPanels}
        hasMore={hasMoreCompendiumPanels}
        onSubmit={onResultsSubmit}
        onSubmitList={onResultsSubmitList}
        onSearch={handleCompendiumSearch}
        onClose={() => setState({ isModalOpen: false })}
        me={parsedUserData}
      />

      <Prompt
        when={isDirty()}
        message={(location) => {
          if (
            location.pathname.includes(LAB_REPORTS.base) ||
            location.pathname.includes(ORDERS_SENT.base)
          ) {
            return true
          } else {
            return 'You have unsaved changes! Are you sure you want to leave?'
          }
        }}
      />

      <CreateLabOrderModal
        open={showClientModal}
        onCloseClick={() => setShowClientModal(false)}
        editable={{
          clientId: clientId,
          orderType: orderType,
          providerId: provider,
          labType: labType,
        }}
        clearChanges={clearChanges}
      />
    </div>
  )
}
/* eslint-enable */
