import React, { useState } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'
import { NavLink, generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import { NameInitials, ProfilePhoto, Button } from '@labsavvyapp/ui-components'
import get from 'lodash/get'

import style from './ClientDetails.module.css'
import { CLIENTS } from '../../../../config/routes'
import FancyID from '../../../../components/Utils/FancyID'
import dateFormatFromIso from '../../../../utils/date-format-from-iso'
import getAge from '../../../../utils/get-age'
import capitalize from '../../../../utils/capitalize'
import { GetMe } from '../../../../graphql/user/queries'
import { ListInsurance } from '../../../../graphql/insurance/queries'
import InsuranceModal from '../../../../components/Modals/InsuranceModal/InsuranceModal'

export default function ClientDetails({
  client,
  loading,
  isReadOnlyPartnerManager,
}) {
  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const consumerTitle = capitalize(
    get(userData, 'getMe.project.consumers_title', 'Client'),
  )

  const { data: insuranceData } = useQuery(ListInsurance, {
    variables: { patient_id: client?.id },
    skip: !client,
  })

  const [showInsuranceModal, setShowInsuranceModal] = useState(false)

  const handleAddInsurance = () => {
    setShowInsuranceModal(true)
  }

  if (loading || !client) return <Loader active />

  // Format data to display
  const profilePhoto = get(client, 'data.profilePhoto')
  const gender = get(client, 'data.gender')
  const dob = get(client, 'data.dob')

  const address1 = get(client, 'data.address.street')

  const city = client?.data?.address?.city ?? ''
  const state = client?.data?.address?.state ?? ''
  const zip = client?.data?.address?.zip ?? ''
  const address2 = `${city && `${city}, `}${state && `${state} `}${zip}`

  return (
    <div className={style.container} data-test="client-details">
      <h1 className={style.name}>{get(client, 'data.name.display')}</h1>
      <h4 className={style.clientIdContainer}>
        {consumerTitle} ID:&nbsp;
        <NavLink
          className={style.clientId}
          to={generatePath(CLIENTS.view, { clientId: client.id })}
        >
          <FancyID id={client.id} />
        </NavLink>
      </h4>

      {profilePhoto ? (
        <ProfilePhoto
          className={style.profilePhoto}
          size={110}
          url={profilePhoto.url}
        />
      ) : client.data.name.first && client.data.name.last ? (
        <NameInitials
          className={style.profilePhoto}
          firstName={client.data.name.first}
          lastName={client.data.name.last}
          size={110}
        />
      ) : (
        ''
      )}
      <Button onClick={handleAddInsurance}>
        {insuranceData?.listInsurance.length ? 'View' : 'Add'} Insurance
      </Button>
      <div className={style.gender}>{gender}</div>
      {dob && (
        <div>
          {dateFormatFromIso(dob, 'MMMM DD, YYYY')} / Age {getAge(dob)}
        </div>
      )}
      <div>
        {address1 && (
          <span>
            {address1}
            <br />
          </span>
        )}
        {address2 && (
          <span>
            {address2}
            <br />
          </span>
        )}
      </div>
      <div>
        {client.data.phones.map((phone, i) => (
          <div className="phone" key={i}>
            {formatPhoneNumber(`+1${phone.number}`)}{' '}
            {phone.type && `(${phone.type})`}
          </div>
        ))}
      </div>
      <div>
        {Array.isArray(client.data.emails) &&
          client.data.emails.map((email, i) => (
            <div className="email" key={i}>
              {email.address} {email.type && `(${email.type})`}
            </div>
          ))}
      </div>

      {!isReadOnlyPartnerManager && (
        <NavLink
          data-test="button-edit-client"
          to={generatePath(CLIENTS.edit, { clientId: client.id })}
          className={style.editLink}
        >
          edit
        </NavLink>
      )}

      <InsuranceModal
        open={showInsuranceModal}
        onClose={() => setShowInsuranceModal(false)}
      />
    </div>
  )
}
