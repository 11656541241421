/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'

// Styiling
import style from './LabReportListPage.module.css'
import listStyle from './LabReportList.module.css'

// Components
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import ReportRow from './ReportRow'
import ListPageHeader from '../../../components/ListPageHeader/ListPageHeader'
import CreateLabOrderModal from '../../../components/Modals/CreateLabOrderModal/CreateLabOrderModal'

// Utilities
import {
  fetchNext,
  hasMore,
  isEmpty,
  updateURLParameter,
} from '@labsavvyapp/ui-components/lib/utils'
import { useDidUpdateEffect } from '../../../utils/custom-hooks'
import capitalize from '../../../utils/capitalize'
import get from 'lodash/get'

// Constants
import { LAB_REPORTS } from '../../../config/routes'
import { FILTER_OPTIONS, SORT_OPTIONS } from './constants'
import {
  isJSON,
  cleanColumnName,
  defaultColumns,
  getHeaderColumn,
  getQueryVariables,
} from './utils'

// Queries and Mutations
import { ListLabOrders } from '../../../graphql/lab-orders/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'

export default function LabReportListPage({ me }) {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const searchParameter = query.get('search') || ''
  const sortByParameter = query.get('sort') || SORT_OPTIONS.dateAsc.key
  const filterByParameter = query.get('filter') || FILTER_OPTIONS.allResults.key

  const [search, setSearch] = useState(searchParameter)
  const [sortBy, setSortBy] = useState(sortByParameter)
  const [filterBy, setFilterBy] = useState(filterByParameter)
  const [partnerId, setPartnerId] = useState(null)
  const [projectId, setProjectId] = useState(null)

  const { data, loading, fetchMore, refetch } = useQuery(ListLabOrders, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getQueryVariables(sortByParameter, searchParameter, filterByParameter),
    },
  })
  const labOrdersData = camelizeKeys(data)

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )
  const partner = get(parsedUserData, 'getMe.partner.id', null)
  const project = get(parsedUserData, 'getMe.project.id', null)
  const showNewLabOrderButton =
    parsedUserData &&
    !get(parsedUserData, 'getMe.capabilities.isReadOnlyPartnerManager')

  if (partnerId !== partner || projectId !== project) {
    setPartnerId(partner)
    setProjectId(project)
  }

  useEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search, filterBy),
    })
  }, [sortBy, search, filterBy])

  // Refetch data if partner or project change
  useDidUpdateEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search, filterBy),
    })
  }, [partnerId, projectId, filterBy])

  const [showClientModal, setShowClientModal] = useState(false)

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    setSortBy(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  function handleFilterChange(_, { value }) {
    const urlParams = updateURLParameter('filter', value)
    setFilterBy(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    setSearch(value)
    push(`${LAB_REPORTS.base}?${urlParams}`)
  }

  function handleNewLabOrderClick() {
    setShowClientModal(true)
  }

  const getNewLabOrderButton = () => {
    return (
      showNewLabOrderButton && {
        label: 'New Lab Order',
        click: handleNewLabOrderClick,
      }
    )
  }

  // List items
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (labOrdersData) {
    items = labOrdersData.listLabOrders.labOrders
    pagination.page = labOrdersData.listLabOrders.page
    pagination.pages = labOrdersData.listLabOrders.pages
    pagination.total = labOrdersData.listLabOrders.total
  }
  // Identifying all the columns to display. NOTE: ORDERS OF ARRAY ARE VERY IMPORTANT
  // ["patient_name", "filler_id", "short_id", "name", "provider_name", "ordered_date", "report_date", "status", "reviewed", "viewed", "trending", "lab_report_pdf"]
  const partnerColumns =
    parsedUserData?.getMe?.partner?.config?.labReport?.columns.length > 0
      ? parsedUserData?.getMe?.partner?.config?.labReport?.columns
      : defaultColumns

  const finalColumns = partnerColumns.map((partnerColumn) => {
    let columnName = partnerColumn
    let columnWidth = 10
    if (isJSON(partnerColumn)) {
      const columns = JSON.parse(partnerColumn)
      columnName = cleanColumnName(columns.column_name)
      columnWidth = columns.column_width
    }

    return getHeaderColumn(columnName, columnWidth, consumerTitle, listStyle)
  })

  return (
    <div className={style.pageContainer}>
      <ListPageHeader
        title="Lab Reports"
        ctaButton={getNewLabOrderButton()}
        sort={{
          sortOptions: Object.keys(SORT_OPTIONS).map((option) => {
            let label = SORT_OPTIONS[option].text
            if (option == 'nameAsc' || option == 'nameDesc') {
              label = `${consumerTitle} ${label}`
            }
            return {
              text: label,
              value: SORT_OPTIONS[option].key,
              key: SORT_OPTIONS[option].key,
            }
          }),
          sortBy,
          onChange: handleSortChange,
        }}
        filter={{
          filterOptions: Object.keys(FILTER_OPTIONS).map((option) => {
            return {
              text: FILTER_OPTIONS[option].text,
              value: FILTER_OPTIONS[option].key,
              key: FILTER_OPTIONS[option].key,
            }
          }),
          filterBy,
          onChange: handleFilterChange,
        }}
        search={{
          placeholder: 'Search Reports',
          onChange: handleSearchChange,
          text: search,
        }}
      />

      <List>
        <ListHeader className={listStyle.listHeader} columns={finalColumns} />

        <InfiniteScrollList
          scrollableTarget="lab-report-list"
          containerClassName={style.infiniteScrollContainer}
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listLabOrders', 'lab_orders', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {!isEmpty(items) &&
            items.map((item) => (
              <ReportRow
                key={item.id}
                data={item}
                columnsToDisplay={finalColumns}
              />
            ))}

          <ListLoader
            fetched={items.length}
            total={pagination.total}
            loading={loading}
          />

          {!loading && isEmpty(items) && (
            <ListEmpty message="No lab orders found with the current search, sort and filter settings." />
          )}
        </InfiniteScrollList>
      </List>

      <CreateLabOrderModal
        open={showClientModal}
        onCloseClick={() => setShowClientModal(false)}
        defaults={{
          laboratory: me?.partner?.config?.ordering?.default_laboratory,
          order_type: me?.partner?.config?.ordering?.default_bill_type,
          custom_order: me?.partner?.config?.ordering?.enable_custom_order,
        }}
      />
    </div>
  )
}
/* eslint-enable */
