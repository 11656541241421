import React from 'react'
import { Switch, Route } from 'react-router'

import { KITS } from '../config/routes'
import withMainLayout from '../layouts/Main/withMainLayout'
import KitListPage from '../pages/Kits/List/KitListPage'
import KitReportView from '../pages/Kits/View/KitReportViewPage'
import KitResultDetails from '../pages/Kits/View/KitResultDetails'

const KitRoutes = ({ path, me, loggedIn }) => (
  <Route
    path={path}
    render={({ match }) => (
      <Switch>
        <Route
          exact
          path={match.path}
          render={() =>
            withMainLayout(KitListPage, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={KITS.view}
          render={() =>
            withMainLayout(KitReportView, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={KITS.details}
          render={() =>
            withMainLayout(KitResultDetails, {
              me,
              loggedIn,
            })
          }
        />
      </Switch>
    )}
  />
)

export default KitRoutes
