import React, { useState, useEffect } from 'react'
import { Modal, Popup, Icon, Search } from 'semantic-ui-react'

import { Button, InfiniteScrollList } from '@labsavvyapp/ui-components'
import ListItem from './ListItem.jsx'
import Results from './Results.jsx'

import style from './TestSelectionModal.module.css'

const TestSelectionModal = ({
  onNext,
  onSearch,
  onSubmit,
  onClose,
  hasMore,
  open,
  items,
  onSubmitList,
  me,
}) => {
  const [selectedCode, setSelectedCode] = useState()
  const [selectedCodeFromSelectedList, setSelectedCodeFromSelectedList] =
    useState()
  const [modalOpen, setModalOpen] = useState(open)
  const [selectedCodeList, setSelectedCodeList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const partnerConfig = me?.getMe?.partner?.config

  const resetSelections = () => {
    setSelectedCodeList([])
    resetSelectedRight()
    resetSelectedLeft()
  }

  const resetSelectedRight = () => {
    setSelectedCodeFromSelectedList(undefined)
  }
  const resetSelectedLeft = () => {
    setSelectedCode(undefined)
  }

  const handleAddSelection = () => {
    const selectedItem = items.find((item) => item.code === selectedCode)
    const tempSelectedItems = selectedCodeList.filter(
      (item) => item.code !== selectedCode,
    )
    tempSelectedItems.push({
      ...selectedItem,
    })
    setSelectedCodeList(tempSelectedItems)
    resetSelectedLeft()
  }
  const handleRemoveSelection = () => {
    const tempSelectedItems = selectedCodeList.filter(
      (item) => item.code !== selectedCodeFromSelectedList,
    )
    setSelectedCodeList(tempSelectedItems)
    resetSelectedRight()
  }

  // set the first item as the default selected code
  useEffect(() => {
    const active = items ? items[0] : ''
    setSelectedCode(active.code)
  }, [items?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  useEffect(() => {
    onSearch(searchQuery)
  }, [searchQuery]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (code) => {
    resetSelectedRight()
    if (!selectedCodeList.find((selected) => selected.code === code)) {
      setSelectedCode(code)
    }
  }

  const handleSelectFromSelectedList = (code) => {
    resetSelectedLeft()
    setSelectedCodeFromSelectedList(code)
  }
  let selected = items?.find((item) => item.code === selectedCode)

  const handleSearchClear = () => {
    setSearchQuery('')
  }

  return (
    <Modal open={modalOpen} size="large" closeIcon={false}>
      <div className={style.content}>
        {onSearch && (
          <>
            <div className={style.titleHeader}>
              <span>Search</span>
            </div>

            <Search
              className={style.search}
              placeholder="Search for a test by name or test code"
              showNoResults={false}
              onSearchChange={(e, data) => {
                setSearchQuery(data.value)
              }}
              icon={<Icon link name="remove" onClick={handleSearchClear} />}
              value={searchQuery}
            />
          </>
        )}
        <div className={style.tables}>
          {items && items.length > 0 ? (
            <>
              <div className={style.panelsContainer}>
                <div className={style.titleHeader}>
                  <span>Available Test(s)</span>
                </div>
                <ul className={style.list}>
                  <InfiniteScrollList
                    dataLength={items?.length || 0}
                    scrollableTarget="test-selection-list"
                    hasMore={hasMore}
                    next={onNext}
                    saveScrollPosition={false}
                  >
                    {items.map((item) => (
                      <ListItem
                        key={item.code}
                        item={item}
                        selected={item.code === selectedCode}
                        disabled={selectedCodeList.find(
                          (selected) => selected.code === item.code,
                        )}
                        onClick={() => handleSelect(item.code)}
                      />
                    ))}
                  </InfiniteScrollList>
                </ul>
              </div>
              {partnerConfig?.ordering?.defaultLaboratory?.code === 'VNLAB' ? (
                <>
                  <div className={style.aggregatorButtons}>
                    <Button
                      className={style.addButton}
                      onClick={handleAddSelection}
                      disabled={!selectedCode}
                    >
                      &gt;
                    </Button>
                    <hr></hr>
                    <Button
                      className={style.addButton}
                      onClick={handleRemoveSelection}
                      disabled={!selectedCodeFromSelectedList}
                    >
                      &lt;
                    </Button>
                  </div>
                  <div className={style.panelsContainer}>
                    <div className={style.titleHeader}>
                      <span>Selected</span>
                    </div>
                    <ul className={style.list}>
                      <InfiniteScrollList
                        dataLength={selectedCodeList.length ?? 0}
                        scrollableTarget="test-selected-list"
                        // hasMore={hasMore}
                        // next={onNext}
                        saveScrollPosition={false}
                      >
                        {selectedCodeList.map((code) => (
                          <Popup
                            key={`${code.code}_selected_pop`}
                            trigger={
                              <ListItem
                                key={`${code.code}_selected`}
                                item={code}
                                selected={
                                  code.code === selectedCodeFromSelectedList
                                }
                                onClick={() =>
                                  handleSelectFromSelectedList(code.code)
                                }
                              />
                            }
                            content={
                              <ul>
                                {code?.results?.map((result, i) => (
                                  <li key={i}>
                                    <strong>{result.code}</strong> -{' '}
                                    {result.name}
                                  </li>
                                ))}
                              </ul>
                            }
                            position="left center"
                            hideOnScroll={true}
                          />
                        ))}
                      </InfiniteScrollList>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <Results selected={selected} />
                </>
              )}
            </>
          ) : (
            <div className={style.noResults}>
              <h2>No results!</h2>
            </div>
          )}
        </div>
        <div className={style.actions}>
          <Button className={style.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          {partnerConfig?.ordering?.defaultLaboratory?.code === 'VNLAB' ? (
            <>
              <Button
                className={style.addButton}
                onClick={() => {
                  resetSelections()
                  onSubmitList(selectedCodeList)
                }}
                disabled={selectedCodeList.length === 0}
              >
                Add test(s)
              </Button>
            </>
          ) : (
            <>
              <Button
                className={style.addButton}
                onClick={() => onSubmit(selectedCode)}
                disabled={!selectedCode}
              >
                Add test
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default TestSelectionModal
