import React from 'react'
import { SanitizedHTML } from '@labsavvyapp/ui-components'
import style from './KitReportResultCategory.module.css'

const KitReportResultCategory = ({
  children,
  name,
  subtitle,
  summary,
  image,
  style: outStyle,
}) => {
  return (
    <div className={style.container} style={outStyle}>
      <div className={style.category}>
        <h2>{name}</h2>
        {image ? (
          <img alt="Icon" className={style.categoryImage} src={image} />
        ) : null}
      </div>
      {subtitle || summary ? (
        <div className={style.categoryInfo}>
          {subtitle ? <h3>{subtitle}</h3> : null}
          {summary ? (
            <p>
              <SanitizedHTML text={summary} />
            </p>
          ) : null}
        </div>
      ) : null}

      <div>{children}</div>
    </div>
  )
}

export default KitReportResultCategory
