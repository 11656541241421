/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { Loader, Message } from 'semantic-ui-react'
import {
  useParams,
  useHistory,
  useLocation,
  matchPath,
  generatePath,
} from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import {
  Button,
  Modal,
  SavingModal,
  SavedModal,
  YesCancelModal,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import delay from 'await-delay'
import get from 'lodash/get'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import PDFLink from '../../../components/PDFLink'
import ReportDetails from './KitReportDetails'
import Panel from '../../../components/Panel/Panel'
import PreformattedLines from '../../../components/PreformattedLines/PreformattedLines'
import ButtonPlus from '../../../components/Buttons/ButtonPlus'
import { KITS, CLIENTS, LAB_REPORTS } from '../../../config/routes'
import KitReportTestResult from './KitReportTestResult'
import KitReportNotes from './KitReportNotes'
import ProviderDetails from './ProviderDetails'
import KitReportResultCategory from './KitReportResultCategory'
import { GetLabOrder } from '../../../graphql/lab-orders/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'
import {
  ReleaseLabOrder,
  ConfirmAOESubmission,
} from '../../../graphql/lab-orders/mutations.js'
import PageError from '../../../components/PageError/PageError'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import style from './KitReportViewPage.module.css'
import PrintLabelModal from './PrintLabelModal'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import StickyBox from 'react-sticky-box'

const QUEST_AOE = 'AskAtOrderEntryCTA'

const KitReportViewPage = () => {
  const { push, goBack } = useHistory()
  const { labReportId } = useParams()
  const { state: locationState, pathname } = useLocation()

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition))
      sessionStorage.removeItem('scrollPosition')
    }
  }

  // store position in sessionStorage
  const handleClick = (e) => {
    sessionStorage.setItem('scrollPosition', window.pageYOffset)
  }

  // apply saved scroll position on first load
  useEffect(() => {
    handleScrollPosition()
  }, [])

  const { height } = useWindowDimensions()

  // Modal states
  const [showListCtasModal, setShowListCtasModal] = useState(false)
  const [showSavingModal, setShowSavingModal] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmReleaseModal, setShowConfirmReleaseModal] = useState(false)
  const [confirmAOEDisabled, setConfirmAOEDisabledState] = useState({})
  const [showPrintLabelModalForShortId, setShowPrintLabelModalForShortId] =
    useState(null)

  const setConfirmAOEDisabled = (shortID, value) =>
    setConfirmAOEDisabledState((prevState) => ({
      ...prevState,
      [shortID]: value,
    }))

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)

  // Fetch lab Order data
  const {
    data,
    loading: labOrderLoading,
    error,
    refetch,
  } = useQuery(GetLabOrder, {
    variables: {
      id: labReportId,
    },
  })
  const labOrderData = camelizeKeys(data)
  const labOrder = labOrderData && labOrderData.getLabOrder

  // Release lab order mutation
  const [releaseLabOrder] = useMutation(ReleaseLabOrder, {
    refetchQueries: [
      {
        query: GetLabOrder,
        variables: {
          id: labReportId,
        },
      },
    ],
  })

  const [confirmAOESubmission] = useMutation(ConfirmAOESubmission)

  // Error handling
  const [apiError, setApiError] = useState('')

  const handleServerError = (error) => {
    setShowSavingModal(false)
    setShowErrorModal(true)
    setApiError(error.graphQLErrors[0].message)
  }

  const openConfirmReleaseModal = () => {
    setShowConfirmReleaseModal(true)
  }

  const handleReleaseLabOrder = async () => {
    try {
      setShowConfirmReleaseModal(false)
      setShowSavingModal(true)

      await releaseLabOrder({ variables: { labOrderId: labReportId } })

      await delay(1000)
      setShowSavingModal(false)
      setShowSavedModal(true)
      await delay(1000)
    } catch (error) {
      handleServerError(error)
    }
  }

  const isView = () =>
    matchPath(pathname, {
      path: KITS.view,
      exact: true,
    })

  const isEdit = () =>
    matchPath(pathname, {
      path: KITS.edit,
      exact: true,
    })

  /**
   * Checks if the referrer link is a valid one. If not, the fallback is the "Lab Reports" page.
   */
  const hasValidReferrer = (referer) =>
    referer && (referer.includes(KITS.base) || referer.includes(CLIENTS.base))

  /**
   * Gets the Previous page data.
   */
  const getPreviousPage = (referer) => {
    if (!hasValidReferrer(referer)) {
      return {
        textLink: 'Back to Kits',
        url: KITS.base,
        onClick() {
          window.location.href = KITS.base
        },
      }
    }

    if (referer.includes(KITS.base)) {
      return {
        textLink: 'Back to Kits',
        onClick() {
          goBack()
        },
      }
    }

    return {
      textLink: 'Back to Client',
      onClick() {
        goBack()
      },
    }
  }

  const confirmAOE = (shortID) => async () => {
    if (!confirmAOEDisabled[shortID]) {
      setConfirmAOEDisabled(shortID, true)
      await confirmAOESubmission({
        variables: {
          labOrderId: labReportId,
          shortId: shortID,
        },
      })
      refetch()
    }
  }

  // Release results button
  const showReleaseResultsButton =
    isView() && get(parsedUserData, 'getMe.capabilities.canReleaseResults')
  const hasReleasedResults = get(labOrder, 'releasedResults.date')
  const canReleaseResults = get(labOrder, 'hasUnreleasedResults')

  const previousPage = getPreviousPage(locationState && locationState.prevPath)

  if (labOrderLoading) return <Loader active />

  if (error)
    return (
      <PageError message="Lab order not found.">
        <Button type="button" variant="basic" onClick={() => push(KITS.base)}>
          Back to Kits
        </Button>
      </PageError>
    )

  return (
    <div className={style.container}>
      {window.dymo === undefined && (
        <HelmetProvider>
          <Helmet>
            <script
              src={`${process.env.PUBLIC_URL}/dymo.connect.framework.min.js`}
              type="text/javascript"
            ></script>
          </Helmet>
        </HelmetProvider>
      )}
      <DetailsLayout>
        <DetailsLayout.Header>
          <DetailsLayout.Panel>
            <Breadcrumbs
              text={previousPage.textLink}
              onClick={previousPage.onClick}
            />
          </DetailsLayout.Panel>
          <DetailsLayout.Panel size={8}>
            <div className={style.headerRightPanel}>
              {showReleaseResultsButton && (
                <Button
                  data-test="button-release-results"
                  className={style.releaseResultsButton}
                  onClick={() => openConfirmReleaseModal()}
                  disabled={!canReleaseResults}
                >
                  {hasReleasedResults ? 'Results released' : 'Release Results'}
                </Button>
              )}
            </div>
          </DetailsLayout.Panel>
        </DetailsLayout.Header>

        <DetailsLayout.Messages>
          {labOrder.ctas && labOrder.ctas.length ? (
            <DetailsLayout.Panel size={20} className={style.messagesContainers}>
              <Message
                onClick={() => setShowListCtasModal(true)}
                color="teal"
                icon="info"
                content={
                  <div>
                    <span>Review open CTAs</span>
                  </div>
                }
              />
            </DetailsLayout.Panel>
          ) : null}
        </DetailsLayout.Messages>

        <DetailsLayout.Panel className={style.leftPanel}>
          <StickyBox offsetTop={20} offsetBottom={20}>
            <div style={{ height: height - 185 }}>
              <ReportDetails labOrder={labOrder} refetch={refetch} />

              <div className={style.reportNotesHeader}>
                <h2 className={style.reportNotesTitle}>Report Notes</h2>

                <ButtonPlus
                  testId="button-add-note"
                  to={generatePath(LAB_REPORTS.newNote, { labReportId })}
                />
              </div>

              <div className={style.notesContainer}>
                <KitReportNotes />
              </div>
            </div>
          </StickyBox>
        </DetailsLayout.Panel>

        <DetailsLayout.Panel size={8}>
          {labOrder.results.map((result, key) => (
            <KitReportResultCategory
              key={key}
              name={result.name}
              subtitle={result.subtitle}
              summary={result.summary}
              image={result.icon ? result.icon.url : null}
              style={{ paddingBottom: '20px' }}
            >
              {result.tests
                ? result.tests.map((test, index) => (
                    <div
                      key={`KitReportTestResult-${index}`}
                      onClick={() => handleClick()}
                    >
                      <KitReportTestResult editing={isEdit()} test={test} />
                    </div>
                  ))
                : null}

              {result.notes ? (
                <Panel key={`panelNote-${key}`} className={style.reportNotes}>
                  <PreformattedLines lines={result.notes} />
                </Panel>
              ) : null}
            </KitReportResultCategory>
          ))}

          <ProviderDetails details={labOrder && labOrder.providerDetails} />
        </DetailsLayout.Panel>
      </DetailsLayout>

      <PrintLabelModal
        onCloseClick={() => setShowPrintLabelModalForShortId(null)}
        open={showPrintLabelModalForShortId !== null}
        clientNumber={get(
          parsedUserData,
          'getMe.project.account',
          'Client number',
        )}
        labRefNumber={showPrintLabelModalForShortId}
        patientName={labOrder.patient.data.name.display}
      />

      {/* Modals */}
      <SavingModal
        message="We are updating the report, please wait..."
        open={showSavingModal}
      />
      <SavedModal
        open={showSavedModal}
        message="Report has been updated"
        onClose={() => setShowSavedModal(false)}
      />
      <YesCancelModal
        onCloseClick={() => setShowConfirmReleaseModal(false)}
        onYesClick={handleReleaseLabOrder}
        open={showConfirmReleaseModal}
        title="Release Lab Report"
        message="Do you want to release the Lab Report to the Patient?"
      />
      <ErrorModal
        open={showErrorModal}
        text={apiError}
        onCloseClick={() => {
          setShowSavingModal(false)
          setShowErrorModal(false)
        }}
      />

      {/* Modals */}
      <Modal
        className={style.ctasModal}
        title="List of CTAs"
        open={showListCtasModal}
        onCloseClick={() => setShowListCtasModal(false)}
        testId="modal-list-ctas"
      >
        <DetailsLayout.Messages>
          {labOrder.ctas && labOrder.ctas.length ? (
            labOrder.ctas.map(
              (document) =>
                document.typename === QUEST_AOE && (
                  <Message
                    className={style.ctasModal}
                    content={
                      <div>
                        {confirmAOEDisabled[document.shortId] ? (
                          'Retrieving AOE...'
                        ) : (
                          <span>
                            <PDFLink url={document.url}>
                              Quest AOE questions
                            </PDFLink>
                          </span>
                        )}
                        <Button
                          className={style.messageButton}
                          onClick={confirmAOE(document.shortId)}
                          disabled={confirmAOEDisabled[document.shortId]}
                        >
                          Confirm AOE
                        </Button>
                      </div>
                    }
                  />
                ),
            )
          ) : (
            <div>
              <span>No more CTAs found.</span>
              <Button
                className={style.messageButton}
                onClick={() => setShowListCtasModal(false)}
                variant="secondary"
              >
                Close
              </Button>
            </div>
          )}
        </DetailsLayout.Messages>
      </Modal>
    </div>
  )
}

export default KitReportViewPage
