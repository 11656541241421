import React, { useEffect } from 'react'
import { NavLink, useParams, useHistory, generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Button, Loader } from '@labsavvyapp/ui-components'
import StickyBox from 'react-sticky-box'

import { LAB_REPORTS, KITS } from '../../../config/routes'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import { BackwardIcon } from '../../../components/Icons/light'
import { ContainerSize } from '../../../components/Utils/ContainerSize'
import Panel, { PanelHeader, PanelBody } from '../../../components/Panel/Panel'

import KitReportDetails from '../View/KitReportDetails'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import ButtonPlus from '../../../components/Buttons/ButtonPlus'
import LabReportNotes from '../../LabReports/ViewEdit/LabReportNotes'
import ExternalReferences from '../../LabReports/ResultDetails/ExternalReferences/ExternalReferences'
import FlagStatement from '../../LabReports/ResultDetails/FlagStatement'
import TestReferenceFAQ from '../../LabReports/ResultDetails/TestReferenceFAQ'
import LabResultReferenceInfo from '../../LabReports/ResultDetails/LabResultReferenceInfo'
import LabResultNotes from '../../LabReports/ResultDetails/LabResultNotes'
import ReviewBoardApproved from '../../LabReports/ResultDetails/ReviewBoardApproved'
import HistoryChart from '../../LabReports/ResultDetails/HistoryChart/HistoryChart'

import {
  GetLabOrder,
  GetLabOrderHistoryByTestReference,
} from '../../../graphql/lab-orders/queries.js'
import PageError from '../../../components/PageError/PageError'

import style from '../../LabReports/ResultDetails/ResultDetails.module.css'

function getLabResult(id, results) {
  return results.reduce((result, item) => {
    let test = item.tests.find((test) => test.id === id)
    if (test !== undefined) {
      result = test
    }
    return result
  }, null)
}

export default function KitResultDetails() {
  const { push } = useHistory()
  const { labReportId, kitId, resultId } = useParams()

  const labOrderURL = generatePath(KITS.view, {
    labReportId,
    kitId,
  })

  // scroll to top on first load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Fetch Lab Order
  const { data, loading: labOrderLoading } = useQuery(GetLabOrder, {
    variables: { id: labReportId },
  })

  const formattedResponse = camelizeKeys(data)
  const result =
    formattedResponse &&
    getLabResult(resultId, formattedResponse.getLabOrder.results)
  const labOrder = formattedResponse && formattedResponse.getLabOrder

  // Fetch Lab Order History
  const { data: labOrderHistoryData } = useQuery(
    GetLabOrderHistoryByTestReference,
    {
      skip: !result || !result.reference.id,
      variables: {
        labOrderId: labReportId,
        testReferenceId: result && result.reference.id,
      },
    },
  )
  const labOrderHistoryDataResponse = camelizeKeys(labOrderHistoryData)
  const labOrderHistory =
    labOrderHistoryDataResponse &&
    labOrderHistoryDataResponse.getLabOrderHistoryByTestReference

  if (labOrderLoading) return <Loader active />

  if (!result)
    return (
      <PageError message="Lab report not found.">
        <Button variant="basic" onClick={() => push(labOrderURL)}>
          Back to Kits
        </Button>
      </PageError>
    )

  return (
    <div className={style.container}>
      {/* // sticky ends  */}
      <DetailsLayout>
        <DetailsLayout.Header>
          <DetailsLayout.Panel>
            <Breadcrumbs
              text="Back to Kits"
              onClick={() => push(labOrderURL)}
              testId="link-back-to-kits"
            />
          </DetailsLayout.Panel>
        </DetailsLayout.Header>

        <DetailsLayout.Panel className={style.leftPanel}>
          <StickyBox offsetTop={20} offsetBottom={20}>
            <KitReportDetails labOrder={labOrder} />

            <section className={style.reportNotes}>
              <div className={style.notesHeader}>
                <h3 className={style.reportNotesTitle}>Report Notes</h3>

                <ButtonPlus
                  to={generatePath(LAB_REPORTS.newNote, { labReportId })}
                />
              </div>

              <div>
                <LabReportNotes labReportId={labReportId} />
              </div>
            </section>
          </StickyBox>
        </DetailsLayout.Panel>

        <DetailsLayout.Panel>
          <ContainerSize>
            {({ width }) => (
              <div>
                <Panel>
                  <PanelHeader
                    as={NavLink}
                    to={generatePath(KITS.view, { labReportId, kitId })}
                  >
                    <div className={style.backButtonContainer}>
                      <BackwardIcon className={style.backIcon} />
                      Back
                    </div>
                  </PanelHeader>

                  <PanelBody>
                    {/* Reference Info */}
                    <LabResultReferenceInfo result={result} />

                    {/* History Chart */}
                    {labOrderHistory && (
                      <HistoryChart
                        width={width}
                        data={labOrderHistory.units}
                        results={labOrderHistory.results}
                        ranges={labOrderHistory.ranges}
                      />
                    )}

                    {!!result.notes.length && (
                      <LabResultNotes notes={result.notes} />
                    )}

                    {/* Flag Statements */}
                    <FlagStatement result={result} />

                    {/* FAQ */}
                    <TestReferenceFAQ
                      resultSummaries={result.resultSummaries}
                      aka={result.aka}
                    />

                    {/* External References */}
                    <ExternalReferences
                      externalReferences={result.externalReferences.adHoc}
                      result={result}
                    />

                    {/* Review Board Approved stamp */}
                    <ReviewBoardApproved />
                  </PanelBody>
                </Panel>
              </div>
            )}
          </ContainerSize>
        </DetailsLayout.Panel>
      </DetailsLayout>
    </div>
  )
}
