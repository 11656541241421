import React, { useEffect, useRef } from 'react'
import { Route, Redirect, Switch, useLocation } from 'react-router'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { InfiniteScrollListProvider } from '@labsavvyapp/ui-components'

import {
  SIGN,
  LAB_REPORTS,
  CLIENTS,
  PROFILE,
  HOME,
  MESSAGING,
  ORDERS_SENT,
  KITS,
} from '../config/routes'
import withMainLayout from '../layouts/Main/withMainLayout'
import AuthenticationRoutes from './AuthenticationRoutes'
import LabReportsRoutes from './LabReportsRoutes'
import ClientsRoutes from './ClientsRoutes'
import OrderSentRoutes from './OrderSentRoutes'
import Error404Page from '../pages/Errors/Error404Page'
import ProfileRoutes from './ProfileRoutes'
import KitRoutes from './KitRoutes'
import Messaging from '../pages/Messaging/Messaging'
import { GetMe } from '../graphql/user/queries.js'
import { GetSession } from '../graphql/user/queries.js'
import { isAuthenticated, removeCookies } from '../utils/auth'

export default function Routes() {
  const client = useApolloClient()
  const previousLocation = useRef('')

  const location = useLocation()

  useEffect(() => {
    const refreshOnNewLabOrder =
      !previousLocation.current &&
      (location.pathname.includes(LAB_REPORTS.review) ||
        location.pathname.includes(LAB_REPORTS.new))
        ? true
        : false

    const editCreateNewLabOrder =
      (previousLocation.current.includes(LAB_REPORTS.new) &&
        location.pathname.includes(LAB_REPORTS.review)) ||
      (previousLocation.current.includes(LAB_REPORTS.review) &&
        location.pathname.includes(LAB_REPORTS.new))
        ? true
        : false

    if (window) {
      if (refreshOnNewLabOrder || editCreateNewLabOrder) {
        return
      }
      // commented out due to conflict with force logout fix
      // but why clear the storage?
      // else {
      //   window.sessionStorage.clear()
      // }
    }
    previousLocation.current = location.pathname
  }, [location.pathname])

  const { data: sessionData } = useQuery(GetSession)
  const session = sessionData && sessionData.session
  const isLoggedIn = session ? session.isLoggedIn : isAuthenticated()

  const { data: userData, error } = useQuery(GetMe, { skip: !isLoggedIn })
  const user = userData && userData.getMe
  const hasAuthenticationError =
    error &&
    error.graphQLErrors.find(
      ({ message }) => message === 'Unauthorized Access.',
    )

  if (hasAuthenticationError) {
    removeCookies()
    client.resetStore()
  }

  return (
    <Switch>
      <AuthenticationRoutes path={SIGN.base} />

      {isLoggedIn && !hasAuthenticationError ? (
        <InfiniteScrollListProvider>
          <Switch>
            <Route exact path="/">
              <Redirect form="/" to={HOME} />
            </Route>

            <Route path={PROFILE.base}>
              <ProfileRoutes me={user} loggedIn={isLoggedIn} />
            </Route>

            <LabReportsRoutes
              path={LAB_REPORTS.base}
              me={user}
              loggedIn={isLoggedIn}
            />

            <KitRoutes path={KITS.base} me={user} loggedIn={isLoggedIn} />

            <OrderSentRoutes
              path={ORDERS_SENT.base}
              me={user}
              loggedIn={isLoggedIn}
            />

            <ClientsRoutes
              path={CLIENTS.base}
              me={user}
              loggedIn={isLoggedIn}
            />

            <Route
              exact
              path={[MESSAGING.base, MESSAGING.chat]}
              render={() =>
                withMainLayout(Messaging, {
                  me: user,
                  loggedIn: isLoggedIn,
                })
              }
            />

            <Route component={Error404Page} />
          </Switch>
        </InfiniteScrollListProvider>
      ) : (
        <Redirect to={SIGN.in} />
      )}
    </Switch>
  )
}
