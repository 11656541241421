import gql from 'graphql-tag'

const UserNameFragment = gql`
  fragment UserName on User {
    name {
      first
      last
      display
    }
  }
`

const UserEmailsFragment = gql`
  fragment UserEmails on User {
    emails {
      address
    }
  }
`

const UserPhotoFragment = gql`
  fragment UserPhoto on User {
    profile_photo {
      _id
      url
    }
  }
`

export const GetSession = gql`
  query GetSession {
    session @client {
      token
      isLoggedIn
    }
  }
`

export const GetMe = gql`
  query GetMe {
    getMe {
      _id
      ...UserName
      partner {
        _id
        has_stripe_account
        name
        logo {
          url
        }
        config {
          ordering {
            enable_misc_fees
            default_laboratory {
              _id
              name
              code
              key
            }
            default_bill_type
            enable_packages
            enable_custom_order
            price_restrictions
            display_email_preview
          }
          lab_report {
            enabled
            columns
          }
          ecommerce {
            enabled
            columns
          }
        }
      }
      project {
        _id
        consumers_title
      }
      capabilities {
        canReleaseResults
        isAccountOwner
        isSuperAdmin
        isReadOnlyPartnerManager
      }
    }
  }

  ${UserNameFragment}
`

export const GetProfile = gql`
  query GetProfile {
    getMe {
      _id
      ...UserName
      ...UserEmails
      ...UserPhoto
    }
  }

  ${UserNameFragment}
  ${UserEmailsFragment}
  ${UserPhotoFragment}
`

export const GetUserEmails = gql`
  query GetUserEmails {
    getMe {
      _id
      ...UserEmails
    }
  }

  ${UserEmailsFragment}
`

export const GetMyChatUser = gql`
  query GetMyChatUser {
    getMyChatUser: getMe {
      _id
      ...UserName
      ...UserPhoto
    }
  }

  ${UserNameFragment}
  ${UserPhotoFragment}
`

export const GetChatUserProfile = gql`
  query GetChatUserProfile($id: MongoID!) {
    getChatUserProfile: getUser(id: $id) {
      _id
      short_id
      ...UserName
      ...UserPhoto
      gender
      dob
      address {
        street
        city
        state
        zip
      }
      phones {
        type
        number
      }
      ...UserEmails
    }
  }

  ${UserNameFragment}
  ${UserPhotoFragment}
  ${UserEmailsFragment}
`

export const GetLocalChatUserProfile = gql`
  query GetLocalChatUserProfile($id: MongoID!) {
    getChatUserProfile: getUser(id: $id) @client {
      _id
      ...UserName
      ...UserPhoto
      gender
      dob
      address {
        street
        city
        state
        zip
      }
      phones {
        type
        number
      }
      ...UserEmails
    }
  }

  ${UserNameFragment}
  ${UserPhotoFragment}
  ${UserEmailsFragment}
`
