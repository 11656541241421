import React, { useRef, useState } from 'react'
import { Confirm, Image, Dimmer, Loader } from 'semantic-ui-react'
import { Button } from '@labsavvyapp/ui-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import style from './style.module.css'
import {
  faUpload,
  faTrashAlt,
  faExpandAlt,
} from '@fortawesome/pro-light-svg-icons'

/**
 * ImageUpload
 *
 * first use for Insurance Capture
 */
const MAX_FILE_SIZE = 5242880
const IMG_EXTENSION = ['.jpg', '.jpeg', '.png']

const ImageUpload = ({ image, loading, onUpload, onRemove }) => {
  const fileInputRef = useRef(null)
  const [actions, setActions] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const hasExtension = (fileName) => {
    const pattern = `(${IMG_EXTENSION.join('|').replace(/\./g, '\\.')})$`
    return new RegExp(pattern, 'i').test(fileName)
  }

  const handleUpload = (e) => {
    const { files } = e.target
    try {
      if (files.length < 1) {
        const errorMessage = 'No file added'
        throw new Error(errorMessage)
      }

      const file = files[0]
      // Check for file extension
      if (!hasExtension(file.name)) {
        const errorMessage = 'Invalid extension'
        throw new Error(errorMessage)
      }

      // Check for file size
      if (file.size > MAX_FILE_SIZE) {
        const errorMessage = 'Invalid file size'
        throw new Error(errorMessage)
      }

      const reader = new FileReader()
      reader.onload = (() => async () => {
        await onUpload(file)
      })(file)

      reader.readAsDataURL(file)
    } catch (error) {
      throw new Error(error)
    }
  }

  const triggerUpload = () => {
    fileInputRef.current.click()
  }

  const openImage = () => {
    window.open(image?.url)
  }

  const showActions = (flag) => {
    setActions(flag)
  }

  // delete image
  const onCancel = () => setConfirm(false)
  const onConfirm = () => setConfirm(true)
  const confirmDelete = () => {
    setConfirm(false)
    if (onRemove) onRemove()
  }

  return (
    <div className={style.box}>
      {image?.versions?.thumb ? (
        <>
          <Image
            src={image?.versions?.thumb}
            onMouseEnter={() => showActions(true)}
          />
          <div
            className={actions ? style.actions : style.hidden}
            onMouseLeave={() => showActions(false)}
          >
            <Button
              className={style.round}
              onClick={openImage}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faExpandAlt} size="lg" />
            </Button>
            <Button
              className={style.round}
              onClick={onConfirm}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </Button>
            <Confirm
              content="Please confirm that you want to delete this image."
              confirmButton="Confirm"
              open={confirm}
              onCancel={onCancel}
              onConfirm={confirmDelete}
            />
          </div>
        </>
      ) : (
        <>
          <Button
            className={style.round}
            onClick={triggerUpload}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faUpload} size="lg" />
          </Button>
          <input
            accept="image/*"
            ref={fileInputRef}
            type="file"
            onChange={handleUpload}
            className={style.upload}
          />
        </>
      )}
      {loading && (
        <Dimmer active inverted>
          <Loader active inverted />
        </Dimmer>
      )}
    </div>
  )
}

export default ImageUpload
