import React from 'react'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Card } from '@labsavvyapp/ui-components'
import get from 'lodash/get'

import style from './KitReportNote.module.css'

const KitReportNote = ({ data }) => {
  const formatInTimeZone = (date, fmt, tz) =>
    format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

  return (
    <Card shadowed className={style.container}>
      <div className={style.header}>
        <div>{get(data, 'createdBy.name.display')}</div>
        <div>
          {data.createdAt &&
            formatInTimeZone(
              data.createdAt,
              `MM/dd/yyyy 'at' h:mm a`,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            )}
        </div>
      </div>
      <div
        data-test="lab-report-note"
        className={style.text}
        dangerouslySetInnerHTML={{ __html: data.body || '' }}
      />
    </Card>
  )
}

export default KitReportNote
