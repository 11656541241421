import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams, Link } from 'react-router-dom'

// Components
import { Icon } from 'semantic-ui-react'
import { Button } from '@labsavvyapp/ui-components'
import ClientDetails from '../components/ClientDetails/ClientDetails'
import LabReportsList from './LabReportsList/LabReportsList'
import ClientNotes from './ClientNotes'
import CreateLabOrderModal from '../../../components/Modals/CreateLabOrderModal/CreateLabOrderModal'

// Utilities
import { camelizeKeys } from 'humps'
import get from 'lodash/get'
import capitalize from '../../../utils/capitalize'

// Contants
import { CLIENTS } from '../../../config/routes'

// Queries and Mutations
import { ListLabOrders } from '../../../graphql/lab-orders/queries.js'
import { ListNotes } from '../../../graphql/notes/queries.js'
import { GetPatient } from '../../../graphql/clients/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'

// Styling
import style from './ViewPage.module.css'

export default function ClientViewPage(me) {
  const { clientId } = useParams()
  const [showClientModal, setShowClientModal] = useState(false)

  // Fetch client data
  const { data: clientResponse, loading: clientLoading } = useQuery(
    GetPatient,
    {
      variables: { id: clientId },
    },
  )
  const clientData = camelizeKeys(clientResponse)

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const consumerTitle = capitalize(
    get(userData, 'getMe.project.consumers_title', 'Client'),
  )
  const isReadOnlyPartnerManager = get(
    userData,
    'getMe.capabilities.isReadOnlyPartnerManager',
    false,
  )

  // Fetch client lab orders
  const { data, loading: loadingLabOrders } = useQuery(ListLabOrders, {
    variables: {
      filter: { patient: clientId },
      sort: { created_at: 'desc' },
    },
  })
  const parsedLabOrdersData = camelizeKeys(data)
  const labOrders =
    parsedLabOrdersData && parsedLabOrdersData.listLabOrders.labOrders

  // Fetch client notes
  const { data: notesData, loading: loadingNotes } = useQuery(ListNotes, {
    variables: { filter: { patient_id: clientId } },
  })
  const parsedNotesData = camelizeKeys(notesData)
  const notes =
    parsedNotesData &&
    parsedNotesData.listNotes &&
    parsedNotesData.listNotes.notes

  function handleNewLabOrderClick() {
    setShowClientModal(true)
  }

  return (
    <div className={style.container}>
      <div className={style.detailsContainer}>
        <div className={style.breadcrumbs} data-test="breadcrumbs">
          <Link data-test="link-back-to-clients" to={CLIENTS.list}>
            <Icon name="caret left" className={style.breadcrumbsIcon} />
            <span className={style.breadcrumbsText}>
              Back to {consumerTitle}s
            </span>
          </Link>
        </div>

        <ClientDetails
          client={clientData && clientData.getPatient}
          loading={clientLoading}
          isReadOnlyPartnerManager={isReadOnlyPartnerManager}
        />
      </div>

      <div className={style.reportsContainer}>
        <div className={style.reportListHeader}>
          <h3>Lab Reports</h3>

          {!isReadOnlyPartnerManager && (
            <Button onClick={handleNewLabOrderClick}>New Lab Order</Button>
          )}
        </div>

        <LabReportsList data={labOrders} loading={loadingLabOrders} />

        <ClientNotes
          consumer={consumerTitle}
          data={notes}
          loading={loadingNotes}
        />

        <CreateLabOrderModal
          open={showClientModal}
          onCloseClick={() => setShowClientModal(false)}
          defaults={{
            laboratory: me?.me?.partner?.config?.ordering?.default_laboratory,
            order_type: me?.me?.partner?.config?.ordering?.default_bill_type,
            custom_order: me?.me?.partner?.config?.ordering?.enable_custom_order,
          }}
        />
      </div>
    </div>
  )
}
