import gql from 'graphql-tag'

export const ListInsurance = gql`
  query ListInsurance($patient_id: MongoID!) {
    listInsurance(patient_id: $patient_id) {
      _id
      name
      code
      plan_type
      form_type
      subscriber {
        first_name
        last_name
      }
      id_number
      group_name
      group_number
      coverage_date
      front_image
      back_image
    }
  }
`
