/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'

// Styiling
import style from './KitListPage.module.css'
import listStyle from './KitList.module.css'

// Components
import { Icon, Popup } from 'semantic-ui-react'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import ReportRow from './ReportRow.jsx'
import ListPageHeader from '../../../components/ListPageHeader/ListPageHeader.jsx'

// Utilities
import {
  fetchNext,
  hasMore,
  isEmpty,
  updateURLParameter,
} from '@labsavvyapp/ui-components/lib/utils'
import { useDidUpdateEffect } from '../../../utils/custom-hooks.js'
import capitalize from '../../../utils/capitalize.js'
import get from 'lodash/get'

// Constants
import { KITS } from '../../../config/routes.js'
import { SORT_OPTIONS } from './constants.js'

// Queries and Mutations
import { ListLabOrders } from '../../../graphql/lab-orders/queries.js'
import { GetMe } from '../../../graphql/user/queries.js'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {
      laborder_type: 'kit',
    },
    page: 1,
  }

  switch (sortBy) {
    case SORT_OPTIONS.nameAsc.key:
      variables.sort.name = SORT_OPTIONS.nameAsc.value
      break
    case SORT_OPTIONS.nameDesc.key:
      variables.sort.name = SORT_OPTIONS.nameDesc.value
      break

    case SORT_OPTIONS.dateAsc.key:
      variables.sort.created_at = SORT_OPTIONS.dateAsc.value
      break
    case SORT_OPTIONS.dateDesc.key:
      variables.sort.created_at = SORT_OPTIONS.dateDesc.value
      break

    case SORT_OPTIONS.statusAsc.key:
      variables.sort.status = SORT_OPTIONS.statusAsc.value
      break
    default:
      break
  }

  if (search) {
    variables.filter.name = search
  }

  return variables
}

const KitListPage = () => {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const searchParameter = query.get('search') || ''
  const sortByParameter = query.get('sort') || SORT_OPTIONS.dateAsc.key

  const [search, setSearch] = useState(searchParameter)
  const [sortBy, setSortBy] = useState(sortByParameter)
  const [partnerId, setPartnerId] = useState(null)
  const [projectId, setProjectId] = useState(null)

  const { data, loading, fetchMore, refetch } = useQuery(ListLabOrders, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getQueryVariables(sortByParameter, searchParameter),
    },
  })

  const labOrdersData = camelizeKeys(data)

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )
  const partner = get(parsedUserData, 'getMe.partner.id', null)
  const project = get(parsedUserData, 'getMe.project.id', null)

  if (partnerId !== partner || projectId !== project) {
    setPartnerId(partner)
    setProjectId(project)
  }

  useEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search])

  // Refetch data if partner or project change
  useDidUpdateEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [partnerId, projectId])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    setSortBy(value)
    push(`${KITS.base}?${urlParams}`)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    setSearch(value)
    push(`${KITS.base}?${urlParams}`)
  }

  // List items
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (labOrdersData) {
    const { listLabOrders } = labOrdersData

    pagination.page = listLabOrders.page
    pagination.pages = listLabOrders.pages
    pagination.total = listLabOrders.total

    // extract kits from lab orders
    listLabOrders.labOrders.forEach((data) => {
      const { kits } = data
      kits.forEach((kit) => {
        const item = { ...kit.kit, kitId: kit.kit.id }
        const { tracking } = kit
        const latest = tracking.pop()

        delete data.kits
        items.push({
          lab: { ...data },
          kit: { ...item },
          tracking: latest,
          // trackUrl: kit.trackUrl,
          orderNo: kit.orderNo,
        })
      })
    })
  }

  return (
    <div className={style.pageContainer}>
      <ListPageHeader
        title="Kits"
        sort={{
          sortOptions: Object.keys(SORT_OPTIONS).map((option) => {
            let label = SORT_OPTIONS[option].text
            return {
              text: label,
              value: SORT_OPTIONS[option].key,
              key: SORT_OPTIONS[option].key,
            }
          }),
          sortBy,
          onChange: handleSortChange,
        }}
        search={{
          placeholder: 'Search Reports',
          onChange: handleSearchChange,
          text: search,
        }}
      />

      <List>
        <ListHeader
          className={listStyle.listHeader}
          columns={[
            {
              name: 'Patient',
              className: `${listStyle.user} ${listStyle.th}`,
            },
            {
              name: 'Order No',
              className: `${listStyle.orderid} ${listStyle.th}`,
            },
            {
              name: 'Kit Name',
              className: `${listStyle.reportName} ${listStyle.th}`,
            },
            {
              name: 'Lab',
              className: `${listStyle.organization} ${listStyle.th}`,
            },
            {
              name: 'Ordered',
              className: `${listStyle.ordered} ${listStyle.th}`,
            },
            {
              name: 'Status',
              className: `${listStyle.status} ${listStyle.th}`,
            },
            {
              name: (
                <>
                  Reviewed{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content={`Reviewed: the timestamp when the provider user opens the lab report for first time.`}
                    basic
                  />
                </>
              ),
              className: `${listStyle.reviewed} ${listStyle.th}`,
            },
            {
              name: (
                <>
                  Viewed{' '}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content={`Viewed: the timestamp when the ${consumerTitle.toLowerCase()} opens the lab report for the first time.`}
                    basic
                  />
                </>
              ),
              className: `${listStyle.viewed} ${listStyle.th}`,
            },
          ]}
        />

        <InfiniteScrollList
          scrollableTarget="kit-report-list"
          containerClassName={style.infiniteScrollContainer}
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listLabOrders', 'lab_orders', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {!isEmpty(items) &&
            items.map((item, key) => <ReportRow key={key} data={item} />)}

          <ListLoader
            fetched={items.length}
            total={pagination.total}
            loading={loading}
          />

          {!loading && isEmpty(items) && (
            <ListEmpty message="No lab orders found with the current search, sort and filter settings." />
          )}
        </InfiniteScrollList>
      </List>
    </div>
  )
}

export default KitListPage
/* eslint-enable */
