import React, { useState } from 'react'
import { Input, Loader } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'

import SemanticDatepicker from 'react-semantic-ui-datepickers'
import dateIsoUtcString from '../../../utils/date-iso-utc-string'

// Constants
import {
  PLAN_TYPE,
  INSURANCE_PROVIDERS,
} from '../../../pages/Clients/List/constants'

// Styling
import style from './InsuranceModal.module.css'

// Components
import { Form, Button } from '@labsavvyapp/ui-components'
import { Grid, Dropdown } from 'semantic-ui-react'
import ImageUpload from '../../ImageUpload'

const InsuranceForm = ({
  uploadImage,
  editInsurance,
  saveInsurance,
  setInsurance,
  setRemove,
  insurance,
  btnText,
  loading,
  disable,
  close,
}) => {
  const { formatMessage } = useIntl()
  const { clientId } = useParams()
  const [hasError, setHasError] = useState([])

  const handleCoverageDate = (event, data) => {
    setHasError([])
    setInsurance({
      ...insurance,
      coverageDate: data.value,
    })
  }

  const planTypeOptions = PLAN_TYPE.options.map((item) => ({
    text: item.text,
    value: item.value,
    key: `${item.value}-${item.key}`,
  }))

  const providerOptions = INSURANCE_PROVIDERS.options.map((item) => ({
    text: item.text,
    value: item.value,
    key: `${item.value}-${item.key}`,
    // image: item.image
  }))

  const parseFormOutputData = () => ({
    name: insurance.name,
    code: insurance.code,
    plan_type: insurance.planType,
    subscriber: {
      first_name: insurance.firstName,
      last_name: insurance.lastName,
    },
    id_number: insurance.idNumber,
    coverage_date: dateIsoUtcString(insurance.coverageDate),
    group_name: insurance.groupName,
    group_number: insurance.groupNumber,
    front_image: insurance?.frontImage?._id ?? insurance?.frontImage,
    back_image: insurance?.backImage?._id ?? insurance?.backImage,
    patient_id: clientId,
  })

  const handleProvider = (_, { value }) => {
    const provider = INSURANCE_PROVIDERS.options.find(
      (item) => item.value === value,
    )
    setHasError([])
    setInsurance({
      ...insurance,
      name: provider.text,
      code: provider.value,
    })
  }

  const handlePlanType = (_, { value }) => {
    const planType = PLAN_TYPE.options.find((type) => type.value === value)
    setHasError([])
    setInsurance({
      ...insurance,
      planType: planType.value,
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setHasError([])
    setInsurance({
      ...insurance,
      [name]: value,
    })
  }

  const uploadInsuranceImage = async (file, face) => {
    const imageId = await uploadImage(file)

    await editInsurance({
      id: insurance?.id,
      data: {
        [face]: imageId,
        patient_id: clientId,
      },
    })
  }

  const removeInsuranceImage = async (id, face) => {
    setRemove(id, face)
  }

  const validateFormInput = (data) => {
    const keys = []
    const except = ['groupName', 'frontImage', 'backImage']
    for (const [key, val] of Object.entries(data)) {
      if (!val && !except.includes(key)) keys.push(key)
    }

    return keys
  }

  const onSave = async () => {
    const hasErrors = validateFormInput(insurance)
    setHasError(hasErrors)

    if (!hasErrors.length) {
      const insuranceData = parseFormOutputData()
      await saveInsurance(insuranceData)
    }
  }

  const onUpdate = async () => {
    const hasErrors = validateFormInput(insurance)
    setHasError(hasErrors)

    if (!hasErrors.length) {
      const insuranceData = parseFormOutputData()
      await editInsurance({
        id: insurance?.id,
        data: insuranceData,
      })
    }
  }

  return (
    <Form intl={{ formatMessage }}>
      <Loader active={loading} />
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <label className={style.label}>Provider</label>
            <Dropdown
              fluid
              search
              selection
              value={insurance?.code || ''}
              options={providerOptions}
              onChange={handleProvider}
              placeholder="Insurance Provider"
              label="Provider"
              error={hasError.includes('code')}
            />
          </Grid.Column>
          <Grid.Column>
            <label className={style.label}>Plan Type</label>
            <Dropdown
              fluid
              search
              selection
              value={insurance?.planType || ''}
              options={planTypeOptions}
              onChange={handlePlanType}
              placeholder="Plan Type"
              error={hasError.includes('planType')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label className={style.label}>Subscriber Name</label>
            <Input
              name="firstName"
              placeholder="First Name"
              value={insurance?.firstName || ''}
              onChange={handleChange}
              error={hasError.includes('firstName')}
            />
          </Grid.Column>
          <Grid.Column className={style.alignBottom}>
            <Input
              name="lastName"
              placeholder="Last Name"
              value={insurance?.lastName || ''}
              onChange={handleChange}
              error={hasError.includes('lastName')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label className={style.label}>ID Number</label>
            <Input
              name="idNumber"
              value={insurance?.idNumber || ''}
              onChange={handleChange}
              error={hasError.includes('idNumber')}
            />
          </Grid.Column>
          <Grid.Column>
            <label className={style.label}>Coverage Date</label>
            <SemanticDatepicker
              value={insurance?.coverageDate || ''}
              onChange={handleCoverageDate}
              error={hasError.includes('coverageDate')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label className={style.label}>Group Name</label>
            <Input
              name="groupName"
              value={insurance?.groupName || ''}
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column>
            <label className={style.label}>Group Number</label>
            <Input
              name="groupNumber"
              value={insurance?.groupNumber || ''}
              onChange={handleChange}
              error={hasError.includes('groupNumber')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div className={style.imageHeader}>
              <label className={style.label}>Front</label>
            </div>
            {insurance?.id ? (
              <ImageUpload
                image={insurance?.frontImage}
                loading={loading}
                onUpload={(file) => uploadInsuranceImage(file, 'front_image')}
                onRemove={() =>
                  removeInsuranceImage(insurance?.id, 'front_image')
                }
              />
            ) : (
              <div className={style.placeholder}></div>
            )}
          </Grid.Column>
          <Grid.Column>
            <div className={style.imageHeader}>
              <label className={style.label}>Back</label>
            </div>
            {insurance?.id ? (
              <ImageUpload
                image={insurance?.backImage}
                loading={loading}
                onUpload={(file) => uploadInsuranceImage(file, 'back_image')}
                onRemove={() =>
                  removeInsuranceImage(insurance?.id, 'back_image')
                }
              />
            ) : (
              <div className={style.placeholder}></div>
            )}
          </Grid.Column>
        </Grid.Row>
        <div className={style.modalButtonsContainer}>
          <Button
            onClick={insurance?.id ? onUpdate : onSave}
            disabled={loading || disable}
            primary
          >
            Save {btnText} Insurance
          </Button>
          <Button onClick={close} disabled={false} variant="basic">
            Cancel
          </Button>
        </div>
      </Grid>
    </Form>
  )
}

export default InsuranceForm
