import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { Link, generatePath } from 'react-router-dom'
import { ListRow } from '@labsavvyapp/ui-components'
import { KITS } from '../../../config/routes'
import { capitalize, get } from 'lodash'

import style from './KitList.module.css'

const ReportRow = ({ data }) => {
  const { lab, kit, tracking, orderNo } = data
  const { status } = tracking

  const kitId = get(kit, 'id')
  const labReportId = get(lab, 'id')

  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: lab.hasUnreleasedResults,
      })}
      as={Link}
      to={{
        pathname: generatePath(KITS.view, {
          labReportId,
          kitId,
        }),
        state: { prevPath: window.location.pathname },
      }}
      bordered={false}
    >
      <span className={style.user} data-test="report-client">
        {get(lab, 'patient.data.name.display')}
      </span>
      <span className={style.orderid} data-test="order-id">
        {orderNo}
      </span>
      <span className={style.reportName} data-test="kit-name">
        {get(kit, 'name')}
      </span>
      <span className={style.organization}>{get(kit, 'provider.name')}</span>
      <span className={style.ordered}>
        {lab.createdAt && format(new Date(lab.createdAt), 'MM/dd/yyyy')}
      </span>
      <div className={style.status}>
        <span
          className={classnames('ui', 'label', {
            violet: status === 'ordered',
            blue: status === 'Kit Shipped',
            grey: status === 'Kit Collected',
            green: status === 'Ship to Lab',
            pink: status === 'Lab Received',
            orange: status === 'Kit Ordered',
          })}
          style={{
            textTransform: 'capitalize',
          }}
        >
          {capitalize(status)}
        </span>
      </div>
      <span className={style.reviewed}>
        {lab?.resultReviewed?.date
          ? format(new Date(lab.resultReviewed.date), 'MM/dd/yyyy')
          : '-'}
      </span>
      <span className={style.viewed}>
        {lab?.resultViewed?.date
          ? format(new Date(lab.resultViewed.date), 'MM/dd/yyyy')
          : '-'}
      </span>
    </ListRow>
  )
}

export default ReportRow
