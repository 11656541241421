import gql from 'graphql-tag'

//  Review these queries
//  This can be improved and splitted into fragments

export const ListLabOrders = gql`
  query ListLabOrders(
    $filter: LabOrderFilter
    $sort: LabOrderSort = { created_at: asc }
    $limit: PositiveInt = 20
    $page: NonNegativeInt = 1
  ) {
    listLabOrders(filter: $filter, sort: $sort, limit: $limit, page: $page) {
      lab_orders {
        _id
        name
        partner {
          _id
          name
        }
        provider {
          _id
          name
        }
        patient {
          _id
          data {
            name {
              display
            }
          }
        }
        kits {
          order_no
          tracking_url
          tracking {
            url
            status
          }
          kit {
            _id
            name
            compendia_codes
            sku_kit_id
            sku_lab_id
            provider {
              name
            }
          }
        }
        short_id
        filler_id
        summary {
          normal
          abnormal
        }
        status
        first_viewed
        created_at
        has_unreleased_results
        result_viewed {
          date
          by {
            name {
              display
            }
          }
        }
        result_reviewed {
          date
          by {
            name {
              display
            }
          }
        }
      }
      page
      pages
      total
    }
  }
`

export const GetLabOrder = gql`
  query GetLabOrder($id: MongoID!) {
    getLabOrder(id: $id) {
      _id
      name
      short_id
      partner {
        _id
        name
      }
      patient {
        _id
        data {
          name {
            display
          }
        }
      }
      kits {
        order_no
        tracking_url
        tracking {
          url
          status
        }
        kit {
          _id
          name
          compendia_codes
          sku_kit_id
          sku_lab_id
          provider {
            name
          }
        }
      }
      provider_details {
        producer_id
        notes
      }
      req_form {
        _id
        url
      }
      quest_req_form {
        _id
        url
      }
      quest_abn_form {
        _id
        url
      }
      summary {
        normal
        abnormal
      }
      created_at
      status
      results {
        name
        notes
        details
        summary
        subtitle
        icon {
          url
        }
        tests {
          _id
          name
          value
          units
          status
          indicator
          notes
          ref_range
          aka
          flag_statement
          result_summaries {
            what_is_this_test_used_for
            what_might_affect_test_results
            what_should_i_ask_my_clinician
            test_summaries {
              question
              answer
            }
          }
          external_references {
            wikipedia {
              url
              logo {
                url
              }
            }
            medline {
              url
              logo {
                url
              }
            }
            ad_hoc {
              url
              title
            }
          }
          reference {
            _id
          }
        }
      }
      released_results {
        date
      }
      result_viewed {
        date
        by {
          name {
            display
          }
        }
      }
      result_reviewed {
        date
        by {
          name {
            display
          }
        }
      }
      has_unreleased_results
      aoe_url
      order_documents {
        ... on ReqFormPdf {
          url
          short_id
        }
        ... on AbnFormPdf {
          url
          short_id
        }
        ... on AoeUrl {
          url
          short_id
        }
        ... on SpecimenLabelPdf {
          url
          short_id
        }
      }
      req_forms_loading
      ctas {
        ... on AskAtOrderEntryCTA {
          url
          short_id
        }
      }
      short_ids
      result_documents {
        order_short_id
        results_pdf_report {
          url
        }
      }
      result_documents_base64 {
        last_update
        pdf_base64
      }
      documents {
        upload {
          url
          doc_type
          type
          extension
        }
        label
      }
    }
  }
`

export const GetTestReference = gql`
  query GetTestReference($id: MongoID!) {
    getTestReference(id: $id) {
      _id
      name
      result_summaries {
        what_is_this_test_used_for
        what_might_affect_test_results
        what_should_i_ask_my_clinician
        test_summaries {
          question
          answer
        }
      }
      ranges {
        _id
        indicator
        status
        flag_stm
      }
      external_references {
        wikipedia {
          url
          logo {
            url
          }
        }
        medline {
          url
          logo {
            url
          }
        }
      }
    }
  }
`

export const GetLabOrderHistoryByTestReference = gql`
  query GetLabOrderHistoryByTestReference(
    $labOrderId: MongoID!
    $testReferenceId: MongoID!
  ) {
    getLabOrderHistoryByTestReference(
      labOrderId: $labOrderId
      testReferenceId: $testReferenceId
    ) {
      ranges {
        min
        max
        type
      }
      results {
        value
        type
        date
        tooltip
      }
      units
    }
  }
`

export const WebappListPackages = gql`
  query WebappListPackages($search: RegExp, $providerId: MongoID) {
    webappListPackages(
      provider_id: $providerId
      limit: 50
      filter: { name: $search }
    ) {
      packages {
        name
        commission
        package {
          _id
          name
          ls_price
          partner_price
          retail_price
          categories {
            category {
              name
              subtitle
              icon {
                url
              }
              summary
              details
            }
            panels {
              name
              code
              tests {
                order {
                  name
                }
                result {
                  code
                  name
                }
                meta {
                  loinc
                }
              }
            }
          }
          created_by {
            name {
              display
            }
          }
          updated_by {
            name {
              display
            }
          }
          provider {
            name
          }
          marketing {
            summary
            details
          }
        }
      }
    }
  }
`

export const ListFrequentlyOrderedPackages = gql`
  query WebappListPackages {
    webappListPackages(limit: 4) {
      packages {
        name
        commission
        package {
          _id
          name
          provider {
            name
          }
        }
      }
    }
  }
`
export const PartnerCompendium = gql`
  query (
    $filter: PartnerCompendiumFilter!
    $sort: PartnerCompendiumSort
    $limit: PositiveInt
    $page: PositiveInt
  ) {
    partnerCompendium(
      filter: $filter
      sort: $sort
      limit: $limit
      page: $page
    ) {
      panels {
        name
        code
        price
        results {
          name
          code
        }
        partner_pricing {
          id
          item_code
          item_name
          retail_price
          contract_price
          # ls_cost
          # ls_standard_price
          # standard_retail_price
        }
        misc {
          name
          code
          price
        }
      }
      page
      pages
      total
    }
  }
`
