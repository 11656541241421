import React, { useState } from 'react'
import { useParams } from 'react-router'
import {
  Icon,
  AccordionTitle,
  AccordionContent,
  Accordion,
  Popup,
} from 'semantic-ui-react'

import style from './MoreDetails.module.css'

const MoreDetails = ({ data }) => {
  const { kitId } = useParams()
  const [open, setOpen] = useState(false)

  const { kits } = data
  const { kit, trackingUrl } =
    kits.length && kits.find(({ kit }) => kit.id === kitId)

  return (
    <Accordion className={style.container}>
      <AccordionTitle
        active={open}
        onClick={() => setOpen(!open)}
        className={style.title}
      >
        More Details
        <Icon name="dropdown" />
      </AccordionTitle>
      <AccordionContent active={open} className={style.content}>
        <div>Test Kit: {kit?.skuKitId}</div>
        <div>Accession #: {data?.fillerId}</div>
        <div>
          Tracking URL:{' '}
          {trackingUrl && (
            <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
              <Popup
                content="opens url in new tab"
                position="right center"
                trigger={<Icon name="chain" />}
              />
            </a>
          )}
        </div>
      </AccordionContent>
    </Accordion>
  )
}

export default MoreDetails
