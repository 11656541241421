import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'

// Queries and Mutations
import { ListInsurance } from '../../../graphql/insurance/queries'
import {
  AddInsurance,
  EditInsurance,
  DeleteInsurance,
} from '../../../graphql/insurance/mutations.js'
import { UploadImage } from '../../../graphql/files/mutations.js'

// Styling
import style from './InsuranceModal.module.css'

// Components
import { Tab, Menu, Modal } from 'semantic-ui-react'
import InsuranceForm from './InsuranceForm'

const insuranceModel = {
  name: '',
  code: '',
  planType: '',
  firstName: '',
  lastName: '',
  idNumber: '',
  coverageDate: '',
  groupName: '',
  groupNumber: '',
  frontImage: '',
  backImage: '',
}

const parseFormInputData = (data) => ({
  id: data?._id,
  name: data?.name,
  code: data?.code,
  planType: data?.plan_type,
  firstName: data?.subscriber?.first_name,
  lastName: data?.subscriber?.last_name,
  idNumber: data?.id_number,
  coverageDate: new Date(moment(data?.coverage_date).format('YYYY-MM-DD')),
  groupName: data?.group_name,
  groupNumber: data?.group_number,
  frontImage: data?.front_image,
  backImage: data?.back_image,
})

const InsuranceModal = ({ open, onClose }) => {
  const [btnSaveText, setBtnSaveText] = useState('Primary')
  const [secondary, setSecondary] = useState(insuranceModel)
  const [primary, setPrimary] = useState(insuranceModel)
  const [formType, setFormType] = useState('primary')
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const { clientId } = useParams()

  // Mutations
  const [addInsurance, { loading: loadingCreate }] = useMutation(AddInsurance)
  const [editInsurance, { loading: loadingUpdate }] = useMutation(EditInsurance)
  const [deleteInsurance, { loading: loadingDelete }] =
    useMutation(DeleteInsurance)
  const [uploadImage, { loading: loadingUpload }] = useMutation(UploadImage)

  const { data: insuranceData, refetch } = useQuery(ListInsurance, {
    variables: { patient_id: clientId },
  })

  const setInsuranceData = (data, type) => {
    const insurance = data?.listInsurance.find(
      (item) => type === item.form_type,
    )
    if (insurance) {
      const mapped = parseFormInputData(insurance)
      if ('primary' === type) {
        setPrimary(mapped)
      } else {
        setSecondary(mapped)
      }
    }
  }

  useEffect(() => {
    setDisable(loadingUpload)
  }, [loadingUpload])

  useEffect(() => {
    setLoading(loadingCreate || loadingUpdate || loadingDelete)
  }, [loadingCreate, loadingUpdate, loadingDelete])

  useEffect(() => {
    setInsuranceData(insuranceData, 'primary')
    setInsuranceData(insuranceData, 'secondary')
  }, [insuranceData])

  const onRemove = async (id, face) => {
    await deleteInsurance({
      variables: {
        id,
        data: {
          field: face,
        },
      },
    })
    refetch()
  }

  const onUpload = async (file) => {
    try {
      setLoading(true)
      const { data } = await uploadImage({ variables: { file } })
      return data?.uploadImage?._id
    } catch (error) {
      throw new Error(error)
    }
  }

  const onSave = async (data) => {
    await addInsurance({
      variables: {
        data: { ...data, form_type: formType },
      },
    })
    refetch()
  }

  const onEdit = async (insurance) => {
    const { id, data } = insurance
    await editInsurance({
      variables: { id, data },
    })
    refetch()
  }

  const handleClick = (e, { name }) => {
    setFormType(name)
    setBtnSaveText(name)
  }

  // Tab Menu Items & Containers
  const panes = [
    {
      menuItem: (
        <Menu.Item key="primary" name="primary" onClick={handleClick}>
          Primary
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.pane}>
          <InsuranceForm
            disable={disable}
            loading={loading}
            close={onClose}
            insurance={primary}
            btnText={btnSaveText}
            setRemove={onRemove}
            setInsurance={setPrimary}
            saveInsurance={onSave}
            editInsurance={onEdit}
            uploadImage={onUpload}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="secondary" name="secondary" onClick={handleClick}>
          Secondary
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.pane}>
          <InsuranceForm
            loading={loading}
            close={onClose}
            insurance={secondary}
            btnText={btnSaveText}
            setRemove={onRemove}
            setInsurance={setSecondary}
            saveInsurance={onSave}
            editInsurance={onEdit}
            uploadImage={onUpload}
          />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <Modal closeIcon className={style.modal} open={open} onClose={onClose}>
      <h1 className={style.modalHeader}>Insurance Information</h1>
      <Modal.Content>
        <Tab
          menu={{
            borderless: true,
            style: {
              backgroundColor: 'var(--darkPurple)',
              border: 0,
              borderRadius: '20px',
              boxShadow: 'none',
              color: 'var(--white)',
              display: 'flex',
              justifyContent: 'center',
              margin: '1rem auto 1.5rem',
              width: '45%',
            },
          }}
          panes={panes}
        />
      </Modal.Content>
    </Modal>
  )
}

export default InsuranceModal
