import gql from 'graphql-tag'

export const AddInsurance = gql`
  mutation AddInsurance($data: InsuranceInput!) {
    addInsurance(data: $data) {
      _id
    }
  }
`

export const EditInsurance = gql`
  mutation EditInsurance($id: MongoID!, $data: InsuranceInput!) {
    editInsurance(id: $id, data: $data) {
      _id
    }
  }
`

export const DeleteInsurance = gql`
  mutation DeleteInsurance($id: MongoID!, $data: DeleteInput!) {
    deleteInsurance(id: $id, data: $data) {
      _id
    }
  }
`
