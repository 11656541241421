const SIGN_BASE = '/sign'
export const SIGN = {
  base: SIGN_BASE,
  in: `${SIGN_BASE}/in`,
  out: `${SIGN_BASE}/out`,
  profile: `${SIGN_BASE}/profile`,
  recover: `${SIGN_BASE}/recover`,
  recoverConfirmToken: `${SIGN_BASE}/recover/confirm-token`,
  recoverNewPassword: `${SIGN_BASE}/recover/new-password`,
  recoverFinish: `${SIGN_BASE}/recover/finish`,
  up: `${SIGN_BASE}/up`,
}

const ERROR_BASE = '/error'
export const ERROR = {
  unauthorized: `${ERROR_BASE}/401`,
  notFound: `${ERROR_BASE}/404`,
}

const PROFILE_BASE = '/profile'
export const PROFILE = {
  base: PROFILE_BASE,
  profileBase: `${PROFILE_BASE}/profile`,

  accountVerification: `account-verification`,
  newPassword: `new-password`,
  passwordUpdated: `password-updated`,

  billingBase: `${PROFILE_BASE}/billing`,
  billingConnect: `${PROFILE_BASE}/billing/connect`,
  billingHowDoYouPayUs: `${PROFILE_BASE}/billing/how-do-you-pay-us`,
  billingHowDoWePayYou: `${PROFILE_BASE}/billing/how-do-we-pay-you`,
  billingInvoices: `${PROFILE_BASE}/billing/invoices`,
}

const LAB_REPORTS_BASE = '/lab-reports'
export const LAB_REPORTS = {
  base: LAB_REPORTS_BASE,
  new: `${LAB_REPORTS_BASE}/new`,
  newCustom: `${LAB_REPORTS_BASE}/new/custom`,
  newExisting: `${LAB_REPORTS_BASE}/new/existing`,
  review: `${LAB_REPORTS_BASE}/review`,
  newClient: `${LAB_REPORTS_BASE}/new?clientId=:clientId`,
  view: `${LAB_REPORTS_BASE}/:labReportId/view`,
  edit: `${LAB_REPORTS_BASE}/:labReportId/edit`,
  newNote: `${LAB_REPORTS_BASE}/:labReportId/notes/new`,
  editNote: `${LAB_REPORTS_BASE}/:labReportId/notes/:noteId/edit`,
  resultDetails: `${LAB_REPORTS_BASE}/:labReportId/result/:resultId/details`,
}

const KITS_BASE = '/kits'
export const KITS = {
  base: KITS_BASE,
  view: `${KITS_BASE}/:labReportId/kit/:kitId/view`,
  details: `${KITS_BASE}/:labReportId/kit/:kitId/result/:resultId/details`,
}

const ORDERS_SENT_BASE = '/orders-sent'
export const ORDERS_SENT = {
  base: ORDERS_SENT_BASE,
  new: `${ORDERS_SENT_BASE}/new`,
  newCustom: `${ORDERS_SENT_BASE}/new/custom`,
  newExisting: `${ORDERS_SENT_BASE}/new/existing`,
  review: `${ORDERS_SENT_BASE}/review`,
}

const CLIENTS_BASE = '/clients'
export const CLIENTS = {
  base: CLIENTS_BASE,
  list: `${CLIENTS_BASE}/list`,
  new: `${CLIENTS_BASE}/new`,
  newNote: `${CLIENTS_BASE}/:clientId/notes/new`,
  view: `${CLIENTS_BASE}/:clientId/view`,
  edit: `${CLIENTS_BASE}/:clientId/edit`,
  editNote: `${CLIENTS_BASE}/:clientId/notes/:noteId/edit`,
}

export const NOTES = {
  base: '/notes',
  new: 'new',
  edit: ':noteId/edit',
}

const MESSAGING_BASE = '/messaging'
export const MESSAGING = {
  base: MESSAGING_BASE,
  chat: `${MESSAGING_BASE}/:selectedContactId`,
}

export const HOME = LAB_REPORTS.base
