/* eslint-disable */
import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useHistory, useLocation } from 'react-router'
import { camelizeKeys } from 'humps'
import get from 'lodash/get'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
  updateURLParameter,
} from '@labsavvyapp/ui-components/lib/utils'

import { CLIENTS, LAB_REPORTS } from '../../../config/routes'
import ListPageHeader from '../../../components/ListPageHeader/ListPageHeader'
import ClientListRow from './ClientListRow'
import style from './ListPage.module.css'
import listStyle from './ClientList.module.css'
import { SORT_OPTIONS } from './constants'
import { useDidUpdateEffect } from '../../../utils/custom-hooks'
import capitalize from '../../../utils/capitalize'
import { GetMe } from '../../../graphql/user/queries.js'

const GET_PATIENT_LIST = gql`
  query listPatients(
    $limit: PositiveInt = 20
    $page: NonNegativeInt = 1
    $sort: PatientSort = { name: { first: asc } }
    $filter: PatientFilter
  ) {
    listPatients(limit: $limit, page: $page, filter: $filter, sort: $sort) {
      patients {
        _id
        data {
          dob
          gender
          name {
            first
            last
          }
          profile_photo {
            _id
            url
          }
        }
      }
      page
      pages
      total
    }
  }
`
export default function ClientListPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_OPTIONS.defaultSortOption,
  )
  const [search, setSearch] = useState(query.get('search') || '')
  const [partnerId, setPartnerId] = useState(null)
  const [projectId, setProjectId] = useState(null)

  // Fetch clients list
  const { data, loading, fetchMore, refetch } = useQuery(GET_PATIENT_LIST, {
    variables: getQueryVariables(sortBy, search),
    notifyOnNetworkStatusChange: true,
  })
  const clientsResponse = camelizeKeys(data)
  const clientList =
    (clientsResponse &&
      clientsResponse.listPatients &&
      clientsResponse.listPatients.patients) ||
    []

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )
  const showNewClientButton =
    parsedUserData &&
    !get(parsedUserData, 'getMe.capabilities.isReadOnlyPartnerManager')
  const partner = get(parsedUserData, 'getMe.partner.id', null)
  const project = get(parsedUserData, 'getMe.project.id', null)
  if (partnerId !== partner || projectId !== project) {
    setPartnerId(partner)
    setProjectId(project)
  }

  useEffect(() => {
    refetch({
      ...getQueryVariables(sortBy, search),
      page: 1,
    })

    return () => {}
  }, [sortBy, search])

  // Refetch data if partner or project change
  useDidUpdateEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [partnerId, projectId])

  function getQueryVariables(sortBy, search) {
    const variables = { sort: { name: {} }, filter: {} }

    if (sortBy) {
      const selectSortOption = SORT_OPTIONS.options.find(
        (option) => option.queryStringValue === sortBy,
      )
      variables.sort[selectSortOption.key].first = selectSortOption.value
    }

    if (search) {
      variables.filter.name = search
    }

    return variables
  }

  function onSortByChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`${CLIENTS.base}?${urlParams}`)
    setSortBy(value)
  }

  function onSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`${CLIENTS.base}?${urlParams}`)
    setSearch(value)
  }

  const getNewClientButton = () => {
    return (
      showNewClientButton && {
        dataTest: 'button-new-client',
        label: `New ${consumerTitle}`,
        path: CLIENTS.new,
      }
    )
  }

  // Paginator
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (clientsResponse) {
    pagination.page = clientsResponse.listPatients.page
    pagination.pages = clientsResponse.listPatients.pages
    pagination.total = clientsResponse.listPatients.total
  }

  return (
    <div className={style.pageContainer}>
      <ListPageHeader
        title={`${consumerTitle}s`}
        ctaButton={getNewClientButton()}
        sort={{
          sortOptions: SORT_OPTIONS.options.map(
            ({ text, queryStringValue }) => ({
              text,
              value: queryStringValue,
            }),
          ),
          sortBy,
          onChange: onSortByChange,
        }}
        search={{
          placeholder: `Search ${consumerTitle}s`,
          onChange: onSearchChange,
          text: search,
        }}
      />

      <List>
        <ListHeader
          className={listStyle.listHeader}
          columns={[
            { className: listStyle.photo },
            { name: 'Name', className: listStyle.name },
            { name: `${consumerTitle} ID`, className: listStyle.patient_id },
            { name: 'Gender', className: listStyle.gender },
            { name: 'Age', className: listStyle.age },
            { name: 'DOB', className: listStyle.dob },
            { className: listStyle.arrow },
          ]}
        />

        <InfiniteScrollList
          scrollableTarget="client-list"
          dataLength={clientList.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listPatients', 'patients', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {clientList.map((client) => (
            <ClientListRow key={client.id} id={client.id} data={client.data} />
          ))}

          <ListLoader
            fetched={clientList.length}
            total={pagination.total}
            loading={loading}
          />

          {!loading && isEmpty(clientList) && (
            <ListEmpty
              message={`No ${consumerTitle.toLowerCase()}s were found.`}
            />
          )}
        </InfiniteScrollList>
      </List>
    </div>
  )
}
/* eslint-enable */
