import React from 'react'
import { Loader } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Card } from '@labsavvyapp/ui-components'
import { isEmpty } from '@labsavvyapp/ui-components/lib/utils'

import KitReportNote from './KitReportNote'
import { ListNotes } from '../../../graphql/notes/queries.js'
import style from './KitReportNotes.module.css'

const KitReportNotes = () => {
  const { labReportId } = useParams()

  const { data, loading } = useQuery(ListNotes, {
    variables: { filter: { lab_order_id: labReportId } },
  })
  const notesData = camelizeKeys(data)
  const notes = notesData && notesData.listNotes.notes

  return (
    <div className={style.container}>
      {loading && <Loader active inline="centered" />}

      {notes &&
        notes.map((note) => <KitReportNote key={note.id} data={note} />)}

      {!loading && isEmpty(notes) && (
        <Card data-test="card-no-notes" emptyCard shadowed>
          No order notes found.
        </Card>
      )}
    </div>
  )
}

export default KitReportNotes
