import React from 'react'
import classnames from 'classnames'
import { get } from 'lodash'
import { Link, generatePath } from 'react-router-dom'
import { ListRow } from '@labsavvyapp/ui-components'
import { LAB_REPORTS } from '../../../config/routes'
import { getContentColumn } from './utils'

import style from './LabReportList.module.css'

export default function ReportRow({ data, columnsToDisplay }) {
  const { id: labReportId } = data
  const status = get(data, 'status')

  const columns = columnsToDisplay.map((col) =>
    getContentColumn(col.code, col.width, style, data, status),
  )

  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: data.hasUnreleasedResults,
      })}
      as={Link}
      to={{
        pathname: generatePath(LAB_REPORTS.view, { labReportId }),
        state: { prevPath: window.location.pathname },
      }}
      bordered={false}
    >
      {columns}
    </ListRow>
  )
}
