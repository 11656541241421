import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { capitalize, get } from 'lodash'
import { Icon, Popup } from 'semantic-ui-react'
import { FILTER_OPTIONS, KIT_STATUS, SORT_OPTIONS } from './constants'

export const getQueryVariables = (sortBy, search, filterBy) => {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
    page: 1,
  }

  switch (sortBy) {
    case SORT_OPTIONS.nameAsc.key:
      variables.sort.name = SORT_OPTIONS.nameAsc.value
      break
    case SORT_OPTIONS.nameDesc.key:
      variables.sort.name = SORT_OPTIONS.nameDesc.value
      break

    case SORT_OPTIONS.dateAsc.key:
      variables.sort.created_at = SORT_OPTIONS.dateAsc.value
      break
    case SORT_OPTIONS.dateDesc.key:
      variables.sort.created_at = SORT_OPTIONS.dateDesc.value
      break

    case SORT_OPTIONS.statusAsc.key:
      variables.sort.status = SORT_OPTIONS.statusAsc.value
      break
    default:
      break
  }

  if (filterBy) {
    switch (filterBy) {
      case FILTER_OPTIONS.resultViewed.key:
        variables.filter = { name: search, viewed: true }
        break
      case FILTER_OPTIONS.resultNotViewed.key:
        variables.filter = { name: search, viewed: false }
        break
      default:
        variables.filter = { name: search }
        break
    }
  }

  return variables
}

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}

export const defaultColumns = [
  'patient_name',
  'filler_id',
  'short_id',
  'provider_name',
  'ordered_date',
  'status',
  'reviewed',
  'viewed',
  'trending',
  'lab_report_pdf',
]

export const cleanColumnName = (text) =>
  text.trim().toLowerCase().replaceAll(' ', '_')

export const getHeaderColumn = (
  columnName,
  columnWidth,
  consumerTitle,
  listStyle,
) => {
  let tempColumn = {}
  switch (columnName) {
    case 'patient_name':
      tempColumn = {
        name: consumerTitle === 'Client' ? 'Patient' : consumerTitle,
        className: classnames(
          listStyle.user,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'filler_id':
      tempColumn = {
        name: 'Accession #',
        className: classnames(
          listStyle.accession,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'short_id':
      tempColumn = {
        name: 'Order ID',
        className: classnames(
          listStyle.orderid,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'lab_id':
      tempColumn = {
        name: 'Lab ID',
        className: classnames(
          listStyle.orderid,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'name':
      tempColumn = {
        name: 'Report Name',
        className: classnames(
          listStyle.reportName,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'provider_name':
      tempColumn = {
        name: 'Lab',
        className: classnames(
          listStyle.organization,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'ordered_date':
      tempColumn = {
        name: 'Ordered',
        className: classnames(
          listStyle.ordered,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'report_date':
      tempColumn = {
        name: 'Report Date',
        className: classnames(
          listStyle.reportDate,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'status':
      tempColumn = {
        name: 'Status',
        className: classnames(
          listStyle.status,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'reviewed':
      tempColumn = {
        name: (
          <>
            Reviewed{' '}
            <Popup
              trigger={<Icon name="info circle" />}
              content={`Reviewed: the timestamp when the provider user opens the lab report for first time.`}
              basic
            />
          </>
        ),
        className: classnames(
          listStyle.reviewed,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'viewed':
      tempColumn = {
        name: (
          <>
            Viewed{' '}
            <Popup
              trigger={<Icon name="info circle" />}
              content={`Viewed: the timestamp when the ${consumerTitle.toLowerCase()} opens the lab report for the first time.`}
              basic
            />
          </>
        ),
        className: classnames(
          listStyle.viewed,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'trending':
      tempColumn = {
        name: 'Trending',
        className: classnames(
          listStyle.trending,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'lab_report_pdf':
      tempColumn = {
        name: 'Lab Report',
        className: classnames(
          listStyle.report,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    case 'lab_report':
      tempColumn = {
        name: 'Lab Report',
        className: classnames(
          listStyle.report,
          listStyle.th,
          listStyle['width' + columnWidth],
        ),
        code: columnName,
        width: columnWidth,
      }
      break
    default:
      break
  }

  return tempColumn
}

export const getContentColumn = (key, width, style, data, status) => {
  let column = ''
  switch (key) {
    case 'patient_name':
      column = (
        <span
          className={classnames(style.user, style['width' + width])}
          data-test="report-client"
          key={key}
        >
          {get(data, 'patient.data.name.display')}
        </span>
      )
      break
    case 'filler_id':
      column = (
        <span
          className={classnames(style.accession, style['width' + width])}
          data-test="accession-number"
          key={key}
        >
          {data?.fillerId}
        </span>
      )
      break
    case 'short_id':
      column = (
        <span
          className={classnames(style.orderid, style['width' + width])}
          data-test="order-id"
          key={key}
        >
          LS-{data?.shortId}
        </span>
      )
      break
    case 'lab_id':
      column = (
        <span
          className={classnames(style.orderid, style['width' + width])}
          data-test="order-id"
          key={key}
        >
          LS-{data?.shortId}
        </span>
      )
      break
    case 'name':
      column = (
        <span
          className={classnames(style.reportName, style['width' + width])}
          data-test="report-name"
          key={key}
        >
          {data.name}
        </span>
      )
      break
    case 'provider_name':
      column = (
        <span
          className={classnames(style.organization, style['width' + width])}
          key={key}
        >
          {get(data, 'provider.name')}
        </span>
      )
      break
    case 'ordered_date':
      column = (
        <span
          className={classnames(style.ordered, style['width' + width])}
          key={key}
        >
          {data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}
        </span>
      )
      break
    case 'report_date':
      column = (
        <span
          className={classnames(style.ordered, style['width' + width])}
          key={key}
        >
          {data.updatedAt && format(new Date(data.updatedAt), 'MM/dd/yyyy')}
        </span>
      )
      break
    case 'status':
      column = (
        <div
          className={classnames(style.status, style['width' + width])}
          key={key}
        >
          <span
            className={classnames('ui', 'label', {
              violet: status === 'ordered',
              blue: status === 'started',
              grey: status === 'partial',
              green: status === 'completed',
              pink: status === 'final',
              orange: KIT_STATUS.includes(status),
            })}
          >
            {capitalize(get(data, 'status').replace(/_/g, ' '))}
          </span>
        </div>
      )
      break
    case 'reviewed':
      column = (
        <span
          className={classnames(style.reviewed, style['width' + width])}
          key={key}
        >
          {data?.resultReviewed?.date
            ? format(new Date(data.resultReviewed.date), 'MM/dd/yyyy')
            : '-'}
        </span>
      )
      break
    case 'viewed':
      column = (
        <span
          className={classnames(style.viewed, style['width' + width])}
          key={key}
        >
          {data?.resultViewed?.date
            ? format(new Date(data.resultViewed.date), 'MM/dd/yyyy')
            : '-'}
        </span>
      )
      break
    case 'trending':
      column = (
        <span
          key={key}
          className={classnames(style.reportName, style['width' + width])}
        >
          -
        </span>
      )
      break
    case 'lab_report_pdf':
      column = (
        <span
          key={key}
          className={classnames(style.reportName, style['width' + width])}
        >
          -
        </span>
      )
      break
    case 'lab_report':
      column = (
        <span
          key={key}
          className={classnames(style.reportName, style['width' + width])}
        >
          -
        </span>
      )
      break
    default:
      break
  }

  return column
}
